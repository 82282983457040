/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import './map.scss';

const Mapa = ({
  onClick,
  Color = '',
  closeModal,
  host,
  entradas = null,
  moneda,
}) => {
  const [Sections, setSections] = useState([]);
  const [SectionHover, setSectionHover] = useState(null);
  useEffect(() => {
    let blocks = document.querySelectorAll(
      '#DELOSBESOSQUETEDIRIGHT,#NOTECONTARONMALRIGHT,#AMORTOXICORIGHT,#DELOSBESOSQUETEDILEFT,#NOTECONTARONMALLEFT,#AMORTOXICOLEFT,#BOTELLATRASBOTELLALEFT,#BOTELLATRASBOTELLARIGHT,#ADIOSAMORLEFT,#TEFALLELEFT,#ADIOSAMORRIGHT,#TEFALLERIGHT,#GRADASLATERALESRIGHT,#GRADASLATERALESLEFT,#GRADASCENTRALES,#STANDING'
    );
    setSections(blocks);
    const scc = document.querySelector('#scroll-svg');
    console.log(scc.getBoundingClientRect().width, scc.scrollWidth);
    scc.scrollTo(
      (scc.scrollWidth - scc.getBoundingClientRect().width) / 2,
      (scc.scrollHeight - scc.getBoundingClientRect().height) / 2
    );
  }, []);
  useEffect(() => {
    if (Sections.length === 0) return;
    Array.from(Sections).map((s, i) => {
      s.addEventListener('mouseenter', hover);
      s.addEventListener('mouseleave', blur);
      if (entradas[s.id].trim().toLowerCase() === 'agotado') {
        s.style.fill = '#9e9e9e';
        s.style.cursor = 'not-allowed';
      }
      s.addEventListener('click', (e) => click(s.id));
      const childern = s.childNodes;
      if (childern) {
        childern.forEach((ch) => {
          ch.addEventListener('click', (e) => click(s.id));
        });
      }
    });
    return () => {
      Array.from(Sections).map((s, i) => {
        s.removeEventListener('mouseenter', hover);
        s.removeEventListener('mouseleave', blur);
        s.removeEventListener('click', click);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Sections]);
  const hover = (e) => {
    Array.from(Sections).map((s, i) => {
      if (e.target.id === s.id) {
        s.classList.add('active');
        setSectionHover({
          ...e.target.dataset,
          url: entradas[e.target.id],
        });
      }
    });
  };
  const click = (section) => {
    if (
      entradas[section].trim() !== '' &&
      entradas[section].trim().toLowerCase() !== 'agotado'
    ) {
      window.location.href = `${entradas[section]}/?host=${host}`;
    }
  };
  const blur = (e) => {
    Array.from(Sections).map((s, i) => {
      s.classList.remove('active');
      setSectionHover(null);
    });
  };
  return (
    <section id="modalMap">
      <span className="overlay" onClick={() => closeModal()}></span>
      <section id="modalContent">
        <span className="cerrar" onClick={() => closeModal()}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x "
          >
            <g>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </g>
          </svg>
        </span>
        <h3 className="text-center mb-4">Seleccionar zona</h3>
        <div id="scroll-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1689 1409"
            id="mapaV"
          >
            <g id="Maps">
              <path
                id="GRADASCENTRALES"
                className="st0"
                d="M1170.4,794.3l-48,85.2l-0.1,0.2l-46.5,80.6c-105.8,183.2-370.1,183.2-475.9,0
                                l-42.7-73.9l-47.8-92.2H286.1l99.8,192.6c57.5,110.9,139.2,235.8,255.1,294c102.4,51.4,228,57.2,336,20.8
                                c100.8-33.9,185.6-104.7,238.7-196.7l74.4-129l0.2-0.3l102.3-181.5L1170.4,794.3z"
              />
              <polygon
                id="GRADASLATERALESLEFT"
                className="st1"
                points="509.5,794.3 286.1,794.3 210.6,648.7 212.1,648.7 185.8,601.9 406.4,601.9 432.8,648.7 447.7,675.1 505.2,786.6 507.2,790.2"
              />
              <polygon
                id="GRADASLATERALESRIGHT"
                className="st1"
                points="1503.2,601.9 1394.8,794.3 1170.4,794.3 1214.3,716.4 1253.4,648.7 1254.1,648.7 1280.5,601.9"
              />
              <polygon
                id="DELOSBESOSQUETEDILEFT"
                className="st2"
                points="840.2,204.1 840.2,284 663.7,284 663.7,262.5 690.1,262.5 690.1,240.1 734.9,240.1 734.9,204.1"
              />
              <polygon
                id="DELOSBESOSQUETEDIRIGHT"
                className="st2"
                points="1009.3,262.5 1009.3,284 840.2,284 840.2,204.1 938.1,204.1 938.1,240.1 982.9,240.1 982.9,262.5"
              />
              <polygon
                id="NOTECONTARONMALLEFT"
                className="st3"
                points="840.2,284 840.2,352.9 577.3,352.9 577.3,331.3 616.5,331.3 616.5,307.3 631.7,307.3 631.7,284.1 663.7,284.1 663.7,284"
              />
              <polygon
                id="AMORTOXICOLEFT"
                className="st4"
                points="840.2,352.9 840.2,397.7 522,397.7 522,375.3 545.2,375.3 545.2,352.9"
              />
              <rect
                id="BOTELLATRASBOTELLALEFT"
                x="490"
                y="397.7"
                className="st5"
                width="350.1"
                height="90.4"
              />
              <rect
                id="BOTELLATRASBOTELLARIGHT"
                x="840.2"
                y="397.7"
                className="st5"
                width="342.8"
                height="90.4"
              />
              <polygon
                id="AMORTOXICORIGHT"
                className="st4"
                points="1150.9,375.3 1150.9,397.7 840.2,397.7 840.2,352.9 1127.7,352.9 1127.7,375.3"
              />
              <polygon
                id="NOTECONTARONMALRIGHT"
                className="st3"
                points="1095.7,331.3 1095.7,352.9 840.2,352.9 840.2,284 1009.3,284 1009.3,284.1 1041.3,284.1 1041.3,307.3 1056.5,307.3 1056.5,331.3"
              />
              <line
                className="st6"
                x1="1183"
                y1="721.2"
                x2="1181.7"
                y2="723.5"
              />
              <line className="st6" x1="493" y1="723.5" x2="490" y2="718.2" />
              <path
                id="STANDING"
                className="st7"
                d="M1122.5,766.8l-82,145.6l-0.1,0.1l-33.1,57.4c-75.3,130.5-263.6,130.5-338.9,0L638,917.2
                                l-84.8-150.5H1122.5z"
              />
              <path
                className="st8"
                d="M528.6,84h615.9c3.6,0,6.5,2.9,6.5,6.5v95c0,3.6-2.9,6.5-6.5,6.5H528.6c-3.6,0-6.5-2.9-6.5-6.5v-95
                                C522,86.9,525,84,528.6,84z"
              />
              <path
                className="st9"
                d="M764,144.1v4.4h-15.3v-21.8h15.1v4.4h-10v4.2h8.6v4.1h-8.6v4.6L764,144.1z"
              />
              <path
                className="st9"
                d="M780.1,133.1c-0.2-0.2-0.4-0.3-0.7-0.5c-0.4-0.3-0.9-0.5-1.4-0.7c-0.6-0.3-1.2-0.5-1.8-0.6
                                c-0.6-0.2-1.3-0.3-2-0.3c-0.7,0-1.4,0.1-2,0.5c-0.5,0.3-0.7,0.8-0.7,1.4c0,0.3,0.1,0.7,0.3,0.9c0.2,0.3,0.5,0.5,0.9,0.7
                                c0.5,0.2,0.9,0.4,1.4,0.5l2,0.6c1,0.3,1.9,0.6,2.8,0.9c0.8,0.3,1.5,0.7,2.2,1.3c0.6,0.5,1.1,1.1,1.4,1.8c0.7,1.9,0.6,3.9-0.2,5.7
                                c-0.4,0.8-1.1,1.5-1.8,2.1c-0.8,0.5-1.7,0.9-2.6,1.1c-1,0.2-2,0.4-3.1,0.4c-0.8,0-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.3-2.4-0.5
                                c-0.8-0.2-1.6-0.5-2.3-0.9c-0.7-0.3-1.4-0.7-2.1-1.1l2.2-4.4c0.3,0.2,0.5,0.4,0.8,0.6c0.5,0.3,1.1,0.6,1.7,0.9
                                c0.7,0.3,1.5,0.6,2.2,0.8c0.8,0.2,1.6,0.3,2.5,0.3c1.8,0,2.7-0.5,2.7-1.6c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.7-0.5-1.1-0.7
                                c-0.5-0.2-1.1-0.4-1.7-0.6l-2.1-0.6c-0.9-0.3-1.8-0.6-2.6-1c-0.7-0.3-1.3-0.7-1.8-1.2c-0.5-0.5-0.9-1-1.1-1.6
                                c-0.2-0.7-0.4-1.4-0.3-2.2c0-1,0.2-2.1,0.7-3c0.4-0.9,1-1.6,1.8-2.2c0.8-0.6,1.6-1.1,2.6-1.4c1-0.3,2-0.5,3.1-0.5
                                c0.8,0,1.5,0.1,2.3,0.2c0.7,0.1,1.4,0.3,2.1,0.6c0.6,0.2,1.3,0.5,1.9,0.8c0.5,0.3,1.1,0.5,1.6,0.9L780.1,133.1z"
              />
              <path
                className="st9"
                d="M784.6,137.4c0-1.3,0.2-2.7,0.7-3.9c0.5-1.3,1.2-2.5,2.1-3.5c0.9-1,2.1-1.9,3.3-2.5c1.4-0.7,2.9-1,4.5-1
                                c1.8,0,3.5,0.4,5.1,1.3c1.4,0.8,2.5,2,3.2,3.4l-3.9,2.7c-0.2-0.5-0.5-1-0.9-1.4c-0.3-0.4-0.7-0.7-1.1-0.9c-0.4-0.2-0.8-0.4-1.3-0.5
                                c-0.4-0.1-0.8-0.1-1.2-0.1c-0.8,0-1.6,0.2-2.3,0.6c-0.6,0.4-1.2,0.9-1.6,1.5c-0.5,0.6-0.8,1.3-1,2.1c-0.2,0.8-0.3,1.6-0.3,2.4
                                c0,0.8,0.1,1.7,0.4,2.5c0.2,0.8,0.6,1.5,1.1,2.1c0.4,0.6,1,1.1,1.7,1.5c0.7,0.4,1.4,0.5,2.2,0.5c0.9,0,1.8-0.2,2.5-0.7
                                c0.4-0.2,0.8-0.6,1.1-0.9c0.3-0.4,0.6-0.9,0.8-1.4l4.2,2.4c-0.3,0.8-0.8,1.5-1.4,2.2c-0.6,0.6-1.4,1.2-2.1,1.6
                                c-0.8,0.4-1.7,0.8-2.5,1c-0.9,0.2-1.7,0.3-2.6,0.3c-1.5,0-2.9-0.3-4.2-1c-1.2-0.6-2.4-1.5-3.3-2.6c-0.9-1.1-1.6-2.3-2.1-3.6
                                C784.9,140.2,784.6,138.8,784.6,137.4z"
              />
              <path
                className="st9"
                d="M821.6,144.1v4.4h-15.3v-21.8h15.1v4.4h-10v4.2h8.6v4.1h-8.6v4.6L821.6,144.1z"
              />
              <path
                className="st9"
                d="M829.8,136v12.5h-5v-21.8h3.9l10.2,12.8v-12.8h5v21.9h-4.1L829.8,136z"
              />
              <path
                className="st9"
                d="M846,148.5l8.4-21.8h4.1l8.4,21.8h-5.3l-1.7-4.9H853l-1.7,4.9H846z M856.4,132l-2.6,8.1h5.1L856.4,132z"
              />
              <path
                className="st9"
                d="M868.8,148.5v-21.8h9.8c1,0,2,0.2,2.8,0.7c0.9,0.4,1.6,1,2.3,1.7c0.6,0.7,1.1,1.5,1.5,2.4
                                c0.3,0.9,0.5,1.8,0.5,2.7c0,0.7-0.1,1.3-0.2,1.9c-0.2,0.6-0.4,1.2-0.7,1.8c-0.3,0.5-0.7,1-1.1,1.5c-0.4,0.4-0.9,0.8-1.5,1.1l5,8.1
                                h-5.7l-4.4-7h-3.3v7H868.8z M873.8,137.1h4.6c0.6,0,1.2-0.3,1.5-0.8c0.5-0.6,0.7-1.4,0.7-2.2c0-0.8-0.2-1.6-0.7-2.2
                                c-0.4-0.5-1-0.8-1.6-0.8h-4.5V137.1z"
              />
              <path
                className="st9"
                d="M889.7,148.5v-21.8h5v21.9L889.7,148.5z"
              />
              <path
                className="st9"
                d="M908.1,148.7c-1.5,0-2.9-0.3-4.2-0.9c-1.2-0.6-2.4-1.5-3.3-2.5c-1.9-2.1-2.9-4.9-2.9-7.7c0-2.8,1.1-5.6,3-7.7
                                c0.9-1,2.1-1.8,3.3-2.4c1.3-0.6,2.7-0.9,4.2-0.9c1.5,0,2.9,0.3,4.2,1c1.2,0.6,2.4,1.5,3.3,2.5c0.9,1,1.6,2.3,2.1,3.6
                                c0.5,1.3,0.7,2.7,0.7,4.1c0,1.4-0.3,2.8-0.8,4.1c-0.5,1.3-1.2,2.5-2.2,3.5c-0.9,1-2.1,1.9-3.3,2.4
                                C911,148.5,909.6,148.8,908.1,148.7z M902.9,137.6c0,0.8,0.1,1.7,0.3,2.4c0.2,0.8,0.6,1.5,1,2.1c0.4,0.6,1,1.1,1.7,1.5
                                c0.7,0.4,1.5,0.6,2.3,0.6c0.8,0,1.6-0.2,2.3-0.6c0.7-0.4,1.2-0.9,1.7-1.5c0.5-0.6,0.8-1.4,1-2.1c0.2-0.8,0.3-1.6,0.3-2.4
                                c0-0.8-0.1-1.6-0.3-2.4c-0.2-0.7-0.5-1.5-1-2.1c-0.4-0.6-1-1.1-1.7-1.4c-0.7-0.4-1.5-0.6-2.2-0.5c-0.8,0-1.6,0.2-2.3,0.6
                                c-0.7,0.4-1.2,0.9-1.7,1.5c-0.5,0.6-0.8,1.4-1,2.1C903,136,902.9,136.8,902.9,137.6z"
              />
              <path
                className="st9"
                d="M873,230.9c2.4,0,4.3,1.9,4.3,4.3c0,2.4-1.9,4.3-4.3,4.3h-7.6v-8.6H873z M872.4,233.7h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C873.6,234.3,873,233.8,872.4,233.7L872.4,233.7z"
              />
              <path
                className="st9"
                d="M878.1,230.9h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V230.9z"
              />
              <path
                className="st9"
                d="M898.1,230.9v5.9h6.7v2.7h-10v-8.6H898.1z"
              />
              <path
                className="st9"
                d="M916.8,234.9v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S916.8,231.7,916.8,234.9
                                z M913.4,235.1c0-1.4-1.2-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V235.1z"
              />
              <path
                className="st9"
                d="M923.3,234.2c2.4,0.1,5.8,0.3,5.8,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.7-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C921,234.1,922,234.2,923.3,234.2z"
              />
              <path
                className="st9"
                d="M876.8,252.9c0,1.5-1.2,2.3-2.7,2.3h-8.8v-8.6h8.2c1.5,0,2.7,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C876.2,251,876.9,251.9,876.8,252.9z M868.7,248.7v1.1h3.9c0.3,0,0.5-0.3,0.5-0.7c0-0.3-0.2-0.5-0.5-0.5H868.7z M873.2,253.2
                                c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6h-4.5v1.1L873.2,253.2z"
              />
              <path
                className="st9"
                d="M877.8,246.6h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10L877.8,246.6z"
              />
              <path
                className="st9"
                d="M894.6,249.9c2.4,0.1,5.8,0.3,5.7,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C892.2,249.8,893.2,249.9,894.6,249.9z"
              />
              <path
                className="st9"
                d="M912.6,250.6v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S912.6,247.4,912.6,250.6
                                z M909.3,250.8c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V250.8z"
              />
              <path
                className="st9"
                d="M919.2,249.9c2.4,0.1,5.8,0.3,5.8,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C916.9,249.8,917.9,249.9,919.2,249.9z"
              />
              <path
                className="st9"
                d="M877.1,266.9c0.1,1.4-0.6,2.6-1.8,3.3l1.8,2.5h-3.6l-1.1-1.7c-0.4,0-0.8,0-1.2,0c-3.2,0-5.9-0.9-5.9-4.2v-0.6
                                c0-3.2,2.7-4.2,5.9-4.2s5.9,1,5.9,4.2V266.9z M873.7,266.7v-0.2c0-1.4-1.2-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7
                                S873.7,268.1,873.7,266.7L873.7,266.7z"
              />
              <path
                className="st9"
                d="M886.4,262.3h3.3v4.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-4.6h3.3v4.4c0,1.4,1.1,1.7,2.5,1.7
                                s2.5-0.3,2.5-1.7L886.4,262.3z"
              />
              <path
                className="st9"
                d="M891.2,262.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V262.3z"
              />
              <path
                className="st9"
                d="M911.9,270.9V265h-4v-2.7h11.3v2.7h-4v5.9L911.9,270.9z"
              />
              <path
                className="st9"
                d="M920.3,262.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V262.3z"
              />
              <path
                className="st9"
                d="M944.7,262.3c2.4,0.1,4.2,2.1,4.1,4.5c-0.1,2.2-1.9,4-4.1,4.1H937v-8.6H944.7z M944,265h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C945.2,265.6,944.7,265.1,944,265z"
              />
              <path className="st9" d="M953.1,262.3v8.6h-3.3v-8.6H953.1z" />
              <path
                className="st9"
                d="M751.5,230.9c2.4,0.2,4.2,2.2,4,4.6c-0.1,2.1-1.9,3.9-4,4h-7.6v-8.6H751.5z M750.9,233.7h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C752.1,234.3,751.6,233.8,750.9,233.7z"
              />
              <path
                className="st9"
                d="M756.6,230.9h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V230.9z"
              />
              <path
                className="st9"
                d="M776.6,230.9v5.9h6.7v2.7h-10v-8.6H776.6z"
              />
              <path
                className="st9"
                d="M795.3,234.9v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S795.3,231.7,795.3,234.9
                                z M791.9,235.1c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V235.1z"
              />
              <path
                className="st9"
                d="M801.9,234.2c2.4,0.1,5.8,0.3,5.7,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C799.5,234.1,800.5,234.2,801.9,234.2z"
              />
              <path
                className="st9"
                d="M759.5,252.9c0,1.5-1.2,2.3-2.7,2.3H748v-8.6h8.2c1.5,0,2.8,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C758.8,251,759.5,251.9,759.5,252.9z M751.3,248.7v1.1h3.9c0.3,0,0.5-0.3,0.5-0.7c0-0.3-0.2-0.5-0.5-0.5H751.3z M755.8,253.2
                                c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6h-4.5v1.1L755.8,253.2z"
              />
              <path
                className="st9"
                d="M760.4,246.6h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V246.6z"
              />
              <path
                className="st9"
                d="M777.2,249.9c2.4,0.1,5.8,0.3,5.7,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.7-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C774.8,249.8,775.9,249.9,777.2,249.9z"
              />
              <path
                className="st9"
                d="M795.3,250.6v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S795.3,247.4,795.3,250.6
                                z M791.9,250.8c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V250.8z"
              />
              <path
                className="st9"
                d="M801.9,249.9c2.4,0.1,5.8,0.3,5.7,2.4c0,2.4-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C799.5,249.8,800.5,249.9,801.9,249.9z"
              />
              <path
                className="st9"
                d="M731.6,266.9c0.1,1.4-0.6,2.6-1.8,3.3l1.8,2.5h-3.6L727,271c-0.4,0-0.8,0-1.2,0c-3.2,0-5.9-0.9-5.9-4.2v-0.6
                                c0-3.2,2.6-4.2,5.9-4.2s5.9,1,5.8,4.2L731.6,266.9z M728.3,266.7v-0.2c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2
                                c0,1.4,1.2,1.6,2.5,1.7S728.3,268.1,728.3,266.7z"
              />
              <path
                className="st9"
                d="M740.9,262.3h3.3v4.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-4.6h3.3v4.4c0,1.4,1.1,1.7,2.5,1.7
                                s2.5-0.3,2.5-1.7L740.9,262.3z"
              />
              <path
                className="st9"
                d="M745.8,262.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V262.3z"
              />
              <path
                className="st9"
                d="M766.5,270.9V265h-4v-2.7h11.3v2.7h-4v5.9L766.5,270.9z"
              />
              <path
                className="st9"
                d="M774.9,262.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V262.3z"
              />
              <path
                className="st9"
                d="M799.2,262.3c2.4,0.1,4.2,2.1,4.1,4.5c-0.1,2.2-1.9,4-4.1,4.1h-7.6v-8.6H799.2z M798.6,265h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C799.8,265.7,799.3,265.1,798.6,265z"
              />
              <path className="st9" d="M807.6,262.3v8.6h-3.3v-8.6H807.6z" />
              <path
                className="st9"
                d="M638.7,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5V317.1z"
              />
              <path
                className="st9"
                d="M654.9,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S654.9,317.8,654.9,321.1z
                                M651.6,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.2,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M665.5,325.7v-5.9h-4v-2.7h11.3v2.7h-4v5.9H665.5z"
              />
              <path
                className="st9"
                d="M674,317.1h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V317.1z"
              />
              <path
                className="st9"
                d="M702.4,322.1c-0.2,2.9-2.8,3.8-5.8,3.8s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2s5.6,0.9,5.8,3.8h-3.4
                                c-0.3-0.9-1.3-1-2.4-1c-1.4,0-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7c1.2,0,2.1-0.2,2.4-1L702.4,322.1z"
              />
              <path
                className="st9"
                d="M714.9,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S714.9,317.8,714.9,321.1z
                                M711.5,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M724.3,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5L724.3,317.1z"
              />
              <path
                className="st9"
                d="M732.8,325.7v-5.9h-4v-2.7H740v2.7h-4v5.9H732.8z"
              />
              <path
                className="st9"
                d="M742.5,317.1h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L742.5,317.1z M743.3,322.1h2.4l-1.2-2.5L743.3,322.1
                                z"
              />
              <path
                className="st9"
                d="M759.5,317.1c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.4,1.2l1.9,2.4h-3.6
                                l-1.6-2.1h-3v2.1h-3.3v-8.6L759.5,317.1z M754.6,319.6v1.5h4.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
                                c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H754.6z"
              />
              <path
                className="st9"
                d="M775.3,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S775.3,317.8,775.3,321.1z
                                M771.9,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M784.7,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5L784.7,317.1z"
              />
              <path
                className="st9"
                d="M794.7,317.1h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.4,4.6h-1.7l-3.3-4.6v4.6h-3.3L794.7,317.1z"
              />
              <path
                className="st9"
                d="M814.6,317.1h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5H810L814.6,317.1z M815.4,322.1h2.4l-1.2-2.5L815.4,322.1z
                                "
              />
              <path
                className="st9"
                d="M826.8,317.1v5.9h6.7v2.7h-10v-8.6H826.8z"
              />
              <path
                className="st9"
                d="M856.9,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5L856.9,317.1z"
              />
              <path
                className="st9"
                d="M873.2,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S873.2,317.8,873.2,321.1z
                                M869.8,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M883.8,325.7v-5.9h-4v-2.7h11.3v2.7h-4v5.9H883.8z"
              />
              <path
                className="st9"
                d="M892.2,317.1h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V317.1z"
              />
              <path
                className="st9"
                d="M920.6,322.1c-0.2,2.9-2.8,3.8-5.8,3.8s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2s5.6,0.9,5.8,3.8h-3.4
                                c-0.3-0.9-1.3-1-2.4-1c-1.4,0-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7c1.2,0,2.1-0.2,2.4-1L920.6,322.1z"
              />
              <path
                className="st9"
                d="M933.2,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S933.2,317.8,933.2,321.1z
                                M929.8,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M942.5,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5L942.5,317.1z"
              />
              <path
                className="st9"
                d="M951,325.7v-5.9h-4v-2.7h11.3v2.7h-4v5.9H951z"
              />
              <path
                className="st9"
                d="M960.7,317.1h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L960.7,317.1z M961.5,322.1h2.3l-1.2-2.5L961.5,322.1
                                z"
              />
              <path
                className="st9"
                d="M977.8,317.1c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.3,1.2l1.9,2.4h-3.6
                                l-1.6-2.1h-3v2.1h-3.3v-8.6L977.8,317.1z M972.9,319.6v1.5h4.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
                                c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H972.9z"
              />
              <path
                className="st9"
                d="M993.6,321.1v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-1-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S993.6,317.8,993.6,321.1z
                                M990.2,321.3c0-1.4-1.1-1.6-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V321.3z"
              />
              <path
                className="st9"
                d="M1002.9,317.1h3.3v8.6h-3.8l-4.6-5.2v5.2h-3.3v-8.6h4.6l3.8,4.5L1002.9,317.1z"
              />
              <path
                className="st9"
                d="M1012.9,317.1h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.3,4.6h-1.7l-3.3-4.6v4.6h-3.3L1012.9,317.1z"
              />
              <path
                className="st9"
                d="M1032.8,317.1h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L1032.8,317.1z M1033.7,322.1h2.3l-1.2-2.5
                                L1033.7,322.1z"
              />
              <path
                className="st9"
                d="M1045,317.1v5.9h6.7v2.7h-10v-8.6H1045z"
              />
              <path
                className="st9"
                d="M637.7,372.4h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L637.7,372.4z M638.5,377.4h2.3l-1.2-2.5L638.5,377.4
                                z"
              />
              <path
                className="st9"
                d="M646.6,372.4h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.3,4.6h-1.7l-3.3-4.6v4.6h-3.3V372.4z"
              />
              <path
                className="st9"
                d="M674.5,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S674.5,373.1,674.5,376.4
                                z M671.2,376.6c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6z"
              />
              <path
                className="st9"
                d="M683.8,372.4c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.3,1.2l1.8,2.4h-3.5
                                l-1.6-2.1h-3v2.1h-3.3v-8.6H683.8z M678.9,374.9v1.5h4.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.7-0.7-0.7
                                H678.9z"
              />
              <path
                className="st9"
                d="M697.7,381v-5.9h-4v-2.7H705v2.7h-4v5.9H697.7z"
              />
              <path
                className="st9"
                d="M716.9,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S716.9,373.1,716.9,376.4
                                z M713.6,376.6c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6z"
              />
              <path
                className="st9"
                d="M725,372.4h4.2l-4.5,4.3l4.5,4.3H725l-2.4-2.5l-2.4,2.5H716l4.5-4.3l-4.5-4.3h4.2l2.4,2.5L725,372.4z"
              />
              <path
                className="st9"
                d="M732.9,372.4v8.6h-3.3v-8.6L732.9,372.4z"
              />
              <path
                className="st9"
                d="M745.6,377.4c-0.2,2.9-2.8,3.8-5.8,3.8s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2s5.6,0.9,5.8,3.8h-3.4
                                c-0.3-0.8-1.3-1-2.4-1c-1.4,0-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7c1.2,0,2.1-0.2,2.4-1L745.6,377.4z"
              />
              <path
                className="st9"
                d="M758.2,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S758.2,373.1,758.2,376.4
                                z M754.8,376.6c0-1.4-1.2-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6z"
              />
              <path
                className="st9"
                d="M915,372.4h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L915,372.4z M915.8,377.4h2.3l-1.2-2.5L915.8,377.4z"
              />
              <path
                className="st9"
                d="M923.8,372.4h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.4,4.6h-1.7l-3.3-4.6v4.6h-3.3V372.4z"
              />
              <path
                className="st9"
                d="M951.8,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S951.8,373.1,951.8,376.4
                                z M948.4,376.6c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6z"
              />
              <path
                className="st9"
                d="M961,372.4c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,1.3-0.7,2.4-1.9,2.9l1.9,2.4h-3.5l-1.6-2.1h-3v2.1h-3.3v-8.6
                                H961z M956.1,374.9v1.5h4.1c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0H956.1z"
              />
              <path
                className="st9"
                d="M974.9,381v-5.9h-4v-2.7h11.3v2.7h-4v5.9H974.9z"
              />
              <path
                className="st9"
                d="M994.2,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S994.2,373.1,994.2,376.4
                                z M990.8,376.6c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6z"
              />
              <path
                className="st9"
                d="M1002.2,372.4h4.2l-4.5,4.3l4.5,4.3h-4.2l-2.4-2.5l-2.4,2.5h-4.2l4.5-4.3l-4.5-4.3h4.2l2.4,2.5L1002.2,372.4z"
              />
              <path
                className="st9"
                d="M1010.2,372.4v8.6h-3.3v-8.6L1010.2,372.4z"
              />
              <path
                className="st9"
                d="M1022.8,377.4c-0.2,2.9-2.8,3.8-5.8,3.8s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2s5.6,0.9,5.8,3.8h-3.4
                                c-0.3-0.8-1.3-1-2.4-1c-1.4,0-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7c1.2,0,2.1-0.2,2.4-1L1022.8,377.4z"
              />
              <path
                className="st9"
                d="M1035.4,376.4v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2
                                S1035.4,373.1,1035.4,376.4z M1032,376.6c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.2,1.7,2.5,1.7s2.5-0.3,2.5-1.7V376.6
                                z"
              />
              <path
                className="st9"
                d="M553.7,444.1c0,1.5-1.2,2.3-2.7,2.3h-8.8v-8.6h8.2c1.5,0,2.7,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C553,442.2,553.7,443.1,553.7,444.1z M545.5,439.9v1.1h3.9c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6H545.5z M550,444.4
                                c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6h-4.5v1.1H550z"
              />
              <path
                className="st9"
                d="M566,441.8v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S566,438.6,566,441.8z
                                M562.7,442c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.2,1.6,2.5,1.7s2.5-0.3,2.5-1.7V442z"
              />
              <path
                className="st9"
                d="M570.2,446.4v-5.9h-4v-2.7h11.3v2.7h-4v5.9H570.2z"
              />
              <path
                className="st9"
                d="M578.6,437.9h10v2.2H582v1.1h6.7v2H582v1.1h6.7v2.2h-10L578.6,437.9z"
              />
              <path
                className="st9"
                d="M593.8,437.9v5.9h6.7v2.7h-10v-8.6H593.8z"
              />
              <path className="st9" d="M605,437.9v5.9h6.7v2.7h-10v-8.6H605z" />
              <path
                className="st9"
                d="M616.5,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L616.5,437.9z M617.3,442.9h2.4l-1.2-2.5L617.3,442.9
                                z"
              />
              <path
                className="st9"
                d="M635.7,446.4v-5.9h-4v-2.7H643v2.7h-4v5.9H635.7z"
              />
              <path
                className="st9"
                d="M652.4,437.9c1.8,0,3.2,1.5,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.4,1.2l1.9,2.4h-3.6
                                l-1.6-2.1h-3v2.1h-3.3v-8.6L652.4,437.9z M647.5,440.4v1.5h4.1c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7
                                c0,0,0,0,0,0H647.5z"
              />
              <path
                className="st9"
                d="M660.5,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5H656L660.5,437.9z M661.3,442.9h2.3l-1.2-2.5L661.3,442.9z
                                "
              />
              <path
                className="st9"
                d="M674.7,441.2c2.4,0.1,5.8,0.3,5.8,2.4c0,2.5-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.7-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3H677c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C672.3,441,673.4,441.1,674.7,441.2z"
              />
              <path
                className="st9"
                d="M698.6,444.1c0,1.5-1.2,2.3-2.7,2.3h-8.8v-8.6h8.2c1.5,0,2.7,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C697.9,442.2,698.6,443.1,698.6,444.1z M690.4,439.9v1.1h3.9c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6H690.4z M694.9,444.4
                                c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.6-0.5-0.6h-4.5v1.1H694.9z"
              />
              <path
                className="st9"
                d="M710.9,441.8v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S710.9,438.6,710.9,441.8
                                z M707.6,442c0-1.4-1.2-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7s2.5-0.3,2.5-1.7V442z"
              />
              <path
                className="st9"
                d="M715.2,446.4v-5.9h-4v-2.7h11.3v2.7h-4v5.9H715.2z"
              />
              <path
                className="st9"
                d="M723.6,437.9h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V437.9z"
              />
              <path
                className="st9"
                d="M738.8,437.9v5.9h6.7v2.7h-10v-8.6H738.8z"
              />
              <path
                className="st9"
                d="M749.9,437.9v5.9h6.7v2.7h-10v-8.6H749.9z"
              />
              <path
                className="st9"
                d="M761.5,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L761.5,437.9z M762.3,442.9h2.3l-1.2-2.5L762.3,442.9
                                z"
              />
              <path
                className="st9"
                d="M897.8,444.1c0,1.5-1.2,2.3-2.7,2.3h-8.8v-8.6h8.2c1.5,0,2.8,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C897.1,442.2,897.8,443.1,897.8,444.1z M889.6,439.9v1.1h3.9c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6H889.6z M894.1,444.4
                                c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.6-0.5-0.6h-4.5v1.1H894.1z"
              />
              <path
                className="st9"
                d="M910.1,441.8v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S910.1,438.6,910.1,441.8
                                z M906.7,442c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7s2.5-0.3,2.5-1.7V442z"
              />
              <path
                className="st9"
                d="M914.3,446.4v-5.9h-4v-2.7h11.3v2.7h-4v5.9H914.3z"
              />
              <path
                className="st9"
                d="M922.8,437.9h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V437.9z"
              />
              <path
                className="st9"
                d="M937.9,437.9v5.9h6.7v2.7h-10v-8.6H937.9z"
              />
              <path
                className="st9"
                d="M949.1,437.9v5.9h6.7v2.7h-10v-8.6H949.1z"
              />
              <path
                className="st9"
                d="M960.7,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L960.7,437.9z M961.5,442.9h2.3l-1.2-2.5L961.5,442.9
                                z"
              />
              <path
                className="st9"
                d="M979.8,446.4v-5.9h-4v-2.7h11.3v2.7h-4v5.9H979.8z"
              />
              <path
                className="st9"
                d="M996.5,437.9c1.8,0,3.2,1.5,3.2,3.2c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.3,1.2l1.9,2.4h-3.6l-1.6-2.1h-3
                                v2.1h-3.3v-8.6L996.5,437.9z M991.6,440.4v1.5h4.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.7-0.7-0.7H991.6z"
              />
              <path
                className="st9"
                d="M1004.7,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L1004.7,437.9z M1005.5,442.9h2.4l-1.2-2.5
                                L1005.5,442.9z"
              />
              <path
                className="st9"
                d="M1018.8,441.2c2.4,0.1,5.8,0.3,5.8,2.4c0,2.5-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.4,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.1-2.3,0.8C1016.5,441,1017.5,441.1,1018.8,441.2z"
              />
              <path
                className="st9"
                d="M1042.7,444.1c0,1.5-1.2,2.3-2.7,2.3h-8.8v-8.6h8.2c1.5,0,2.7,0.8,2.7,2.3c0,0.7-0.4,1.4-1,1.8
                                C1042.1,442.2,1042.7,443.1,1042.7,444.1z M1034.6,439.9v1.1h3.9c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6H1034.6z
                                M1039.1,444.4c0.3,0,0.5-0.3,0.5-0.6c0-0.3-0.2-0.5-0.5-0.6h-4.5v1.1H1039.1z"
              />
              <path
                className="st9"
                d="M1055,441.8v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S1055,438.6,1055,441.8z
                                M1051.7,442c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.6,2.5,1.7s2.5-0.3,2.5-1.7V442z"
              />
              <path
                className="st9"
                d="M1059.3,446.4v-5.9h-4v-2.7h11.3v2.7h-4v5.9H1059.3z"
              />
              <path
                className="st9"
                d="M1067.7,437.9h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V437.9z"
              />
              <path
                className="st9"
                d="M1082.9,437.9v5.9h6.7v2.7h-10v-8.6H1082.9z"
              />
              <path
                className="st9"
                d="M1094.1,437.9v5.9h6.7v2.7h-10v-8.6H1094.1z"
              />
              <path
                className="st9"
                d="M1105.6,437.9h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L1105.6,437.9z M1106.4,442.9h2.3l-1.2-2.5
                                L1106.4,442.9z"
              />
              <path
                className="st9"
                d="M777.3,887c3.6,0.2,8.7,0.4,8.7,3.7c0,3.7-4.2,4.6-8.8,4.6s-8.3-0.6-8.8-4.6h5.3c0.6,1,1.9,1.3,3.5,1.3
                                s3.5-0.3,3.5-1.3c0-0.6-1.6-0.7-3.6-0.8c-3.6-0.2-8.7-0.4-8.7-3.7c0-3.7,4.2-4.6,8.8-4.6s8.3,0.5,8.8,4.6h-5.3
                                c-0.6-1-1.9-1.3-3.5-1.3s-3.5,0.2-3.5,1.3C773.7,886.8,775.2,886.9,777.3,887z"
              />
              <path
                className="st9"
                d="M792.1,895v-8.8h-6V882h17.1v4.1h-6v8.9H792.1z"
              />
              <path
                className="st9"
                d="M806.8,882h6l7,13H814l-1-2.2h-6.5l-1,2.2h-5.7L806.8,882z M808,889.6h3.6l-1.8-3.8L808,889.6z"
              />
              <path
                className="st9"
                d="M832.8,882h5.1v13h-5.7l-7-7.9v7.9h-5v-13h7l5.7,6.9L832.8,882z"
              />
              <path
                className="st9"
                d="M852.3,882c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-11.5v-13H852.3z M851.3,886.1h-5.5v4.7h5.5
                                c1.3-0.2,2.2-1.4,2.1-2.7C853.3,887.1,852.4,886.2,851.3,886.1z"
              />
              <path className="st9" d="M865,882v13h-5v-13H865z" />
              <path
                className="st9"
                d="M880.3,882h5.1v13h-5.7l-7-7.9v7.9h-5.1v-13h7l5.7,6.9V882z"
              />
              <path
                className="st9"
                d="M896,887.8h8.8v7.4h-2.5l-0.2-2c-1.7,1.4-3.8,2.1-6,2c-4.9,0-8.9-1.4-8.8-6.3v-1c0-4.9,4-6.3,8.8-6.3
                                c4.5,0,8.2,1.2,8.8,5.2h-5.4c-0.4-0.5-0.9-0.8-1.4-0.9c-0.7-0.2-1.3-0.2-2-0.2c-2.1,0-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5
                                s3.3-0.1,4-1.1h-4V887.8z"
              />
              <path
                className="st9"
                d="M730.7,1202.4h8.8v7.4H737l-0.2-2c-1.7,1.4-3.8,2.1-6,2c-4.9,0-8.9-1.4-8.8-6.3v-1c0-4.9,4-6.3,8.8-6.3
                                c4.5,0,8.2,1.2,8.8,5.2h-5.4c-0.3-0.4-0.8-0.8-1.4-0.9c-0.7-0.2-1.3-0.2-2-0.2c-2.1,0-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5
                                s3.3-0.1,4-1.1h-4.1V1202.4z"
              />
              <path
                className="st9"
                d="M754.3,1196.6c2.7,0,4.9,2.2,4.9,4.9l0,0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4l-2.4-3.2
                                h-4.5v3.2h-5.1v-13L754.3,1196.6z M746.8,1200.4v2.2h6.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.6-0.5-1.1-1.1-1.1
                                H746.8z"
              />
              <path
                className="st9"
                d="M766.5,1196.6h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L766.5,1196.6z M767.7,1204.2h3.6l-1.8-3.8L767.7,1204.2z"
              />
              <path
                className="st9"
                d="M791.4,1196.6c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-11.5v-13H791.4z M790.4,1200.7h-5.5v4.8h5.5
                                c1.3-0.2,2.2-1.4,2-2.7C792.3,1201.7,791.5,1200.9,790.4,1200.7z"
              />
              <path
                className="st9"
                d="M803.5,1196.6h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L803.5,1196.6z M804.7,1204.2h3.6l-1.8-3.8L804.7,1204.2z"
              />
              <path
                className="st9"
                d="M844.1,1204.2c-0.3,4.4-4.2,5.7-8.8,5.7c-4.9,0-8.9-1.4-8.8-6.3v-1c0-4.9,4-6.3,8.8-6.3c4.7,0,8.5,1.3,8.8,5.7
                                H839c-0.5-1.3-1.9-1.6-3.6-1.6c-2.1,0-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5c1.7,0,3.2-0.3,3.6-1.6L844.1,1204.2z"
              />
              <path
                className="st9"
                d="M845.6,1196.6h15.2v3.3h-10.1v1.7h10.1v3h-10.1v1.7h10.1v3.3h-15.2V1196.6z"
              />
              <path
                className="st9"
                d="M876.1,1196.6h5.1v13h-5.7l-7-7.9v7.9h-5.1v-13h7l5.7,6.9V1196.6z"
              />
              <path
                className="st9"
                d="M888.9,1209.6v-8.8h-6v-4.1H900v4.1h-6v8.8H888.9z"
              />
              <path
                className="st9"
                d="M914.2,1196.6c2.7,0,4.9,2.2,4.9,4.9l0,0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4l-2.4-3.2
                                h-4.5v3.2h-5v-13L914.2,1196.6z M906.7,1200.4v2.2h6.2c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0H906.7z"
              />
              <path
                className="st9"
                d="M926.4,1196.6h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L926.4,1196.6z M927.6,1204.2h3.6l-1.8-3.8L927.6,1204.2z"
              />
              <path
                className="st9"
                d="M944.8,1196.6v8.9h10.1v4.1h-15.2v-13L944.8,1196.6z"
              />
              <path
                className="st9"
                d="M268.1,677.5h8.8v7.4h-2.5l-0.2-2c-1.7,1.4-3.8,2.1-6,2c-4.9,0-8.9-1.4-8.9-6.3v-0.9c0-4.9,4-6.3,8.9-6.3
                                c4.5,0,8.2,1.2,8.8,5.2h-5.4c-0.3-0.5-0.8-0.8-1.4-0.9c-0.7-0.2-1.3-0.2-2-0.2c-2.1,0-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5
                                s3.3-0.1,4-1.1h-4.1L268.1,677.5z"
              />
              <path
                className="st9"
                d="M291.7,671.6c2.7,0,4.9,2.2,4.9,4.9c0,0,0,0,0,0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4
                                l-2.4-3.2h-4.5v3.2h-5v-13L291.7,671.6z M284.2,675.4v2.2h6.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
                                c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0H284.2z"
              />
              <path
                className="st9"
                d="M303.9,671.7h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2H297L303.9,671.7z M305.1,679.2h3.6l-1.8-3.8L305.1,679.2z"
              />
              <path
                className="st9"
                d="M328.8,671.7c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-11.5v-13H328.8z M327.8,675.8h-5.5v4.7h5.5
                                c1.3-0.2,2.2-1.4,2.1-2.7C329.7,676.7,328.9,675.9,327.8,675.8z"
              />
              <path
                className="st9"
                d="M340.9,671.7h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2H334L340.9,671.7z M342.1,679.2h3.5l-1.8-3.8L342.1,679.2z"
              />
              <path
                className="st9"
                d="M264.3,695.3v8.9h10.1v4.1h-15.2v-13L264.3,695.3z"
              />
              <path
                className="st9"
                d="M281.8,695.3h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L281.8,695.3z M283,702.9h3.6l-1.8-3.8L283,702.9z"
              />
              <path
                className="st9"
                d="M297.4,708.3v-8.8h-6v-4.1h17.1v4.1h-6v8.8H297.4z"
              />
              <path
                className="st9"
                d="M310.1,695.3h15.2v3.3h-10.1v1.7h10.1v3h-10.1v1.7h10.1v3.3h-15.2V695.3z"
              />
              <path
                className="st9"
                d="M340.4,695.3c2.7,0,4.9,2.2,4.9,4.9l0,0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6H340l-2.4-3.2
                                H333v3.2h-5v-13L340.4,695.3z M333,699.1v2.2h6.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.6-0.5-1.1-1.1-1.1
                                c0,0,0,0,0,0H333z"
              />
              <path
                className="st9"
                d="M352.7,695.3h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L352.7,695.3z M353.9,702.9h3.6l-1.8-3.8L353.9,702.9z"
              />
              <path
                className="st9"
                d="M371,695.3v8.9h10.1v4.1H366v-13L371,695.3z"
              />
              <path className="st9" d="M396.2,695.3v13h-5.1v-13H396.2z" />
              <path
                className="st9"
                d="M398.3,699.4v-4.1h16.8v4.1l-8.9,4.7h8.9v4.1h-16.8v-4.1l8.5-4.7H398.3z"
              />
              <path
                className="st9"
                d="M433.9,702.3c0.1,2-0.9,4-2.6,5l2.6,3.8h-5.4l-1.6-2.6c-0.6,0-1.2,0.1-1.8,0.1c-4.9,0-8.9-1.4-8.9-6.3v-1
                                c0-4.9,4-6.3,8.9-6.3s8.9,1.4,8.9,6.3V702.3z M428.8,702v-0.3c0-2.1-1.7-2.5-3.8-2.5s-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5
                                S428.9,704.1,428.8,702L428.8,702z"
              />
              <path
                className="st9"
                d="M1337.1,677.5h8.8v7.4h-2.5l-0.3-2c-1.7,1.4-3.8,2.1-6,2c-4.9,0-8.9-1.4-8.9-6.3v-0.9c0-4.9,4-6.3,8.9-6.3
                                c4.5,0,8.2,1.2,8.8,5.2h-5.4c-0.3-0.5-0.8-0.8-1.4-0.9c-0.7-0.2-1.3-0.2-2-0.2c-2.1,0-3.8,0.4-3.8,2.5v0.3c0,2.1,1.7,2.5,3.8,2.5
                                s3.3-0.1,4-1.1h-4.1L1337.1,677.5z"
              />
              <path
                className="st9"
                d="M1360.6,671.6c2.7,0,4.9,2.2,4.9,4.9c0,0,0,0,0,0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4
                                l-2.4-3.2h-4.5v3.2h-5.1v-13L1360.6,671.6z M1353.2,675.4v2.2h6.2c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0
                                L1353.2,675.4z"
              />
              <path
                className="st9"
                d="M1372.9,671.7h6l6.9,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L1372.9,671.7z M1374.1,679.2h3.6l-1.8-3.8L1374.1,679.2z
                                "
              />
              <path
                className="st9"
                d="M1397.7,671.7c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-11.5v-13H1397.7z M1396.7,675.8h-5.5v4.7h5.5
                                c1.3-0.2,2.2-1.4,2.1-2.7C1398.7,676.7,1397.8,675.9,1396.7,675.8z"
              />
              <path
                className="st9"
                d="M1409.8,671.7h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L1409.8,671.7z M1411.1,679.2h3.6l-1.8-3.8L1411.1,679.2z"
              />
              <path
                className="st9"
                d="M1241.5,695.3v8.9h10.1v4.1h-15.2v-13L1241.5,695.3z"
              />
              <path
                className="st9"
                d="M1259,695.3h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.8L1259,695.3z M1260.2,702.9h3.6l-1.8-3.8L1260.2,702.9z"
              />
              <path
                className="st9"
                d="M1274.6,708.3v-8.8h-6v-4.1h17.1v4.1h-6v8.8H1274.6z"
              />
              <path
                className="st9"
                d="M1287.3,695.3h15.2v3.3h-10.1v1.7h10.1v3h-10.1v1.7h10.1v3.3h-15.2V695.3z"
              />
              <path
                className="st9"
                d="M1317.7,695.3c2.7,0,4.9,2.2,4.9,4.9v0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4l-2.4-3.2
                                h-4.5v3.2h-5v-13L1317.7,695.3z M1310.2,699.1v2.2h6.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.6-0.5-1.1-1.1-1.1
                                H1310.2z"
              />
              <path
                className="st9"
                d="M1329.9,695.3h6l7,13h-5.7l-1-2.2h-6.5l-1,2.2h-5.7L1329.9,695.3z M1331.1,702.9h3.6l-1.8-3.8L1331.1,702.9z"
              />
              <path
                className="st9"
                d="M1348.3,695.3v8.9h10.1v4.1h-15.2v-13L1348.3,695.3z"
              />
              <path
                className="st9"
                d="M1379.9,695.3c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-11.5v-13H1379.9z M1378.9,699.4h-5.5v4.7h5.6
                                c1.3-0.2,2.2-1.4,2.1-2.7C1380.9,700.4,1380.1,699.6,1378.9,699.4L1378.9,699.4z"
              />
              <path
                className="st9"
                d="M1387.6,695.3h15.2v3.3h-10.1v1.7h10.1v3h-10.1v1.7h10.1v3.3h-15.2V695.3z"
              />
              <path
                className="st9"
                d="M1417.9,695.3c2.7,0,4.9,2.2,4.9,4.9v0c0,0.9-0.3,1.9-0.8,2.7c-0.5,0.8-1.2,1.4-2,1.8l2.8,3.6h-5.4l-2.4-3.2
                                h-4.5v3.2h-5v-13L1417.9,695.3z M1410.5,699.1v2.2h6.2c0.6,0,1.1-0.5,1.1-1.1c0,0,0,0,0,0c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0
                                L1410.5,699.1z"
              />
              <polygon
                id="TEFALLERIGHT"
                className="st10"
                points="1183,605.1 1183,720.4 1181.7,722.7 865.2,722.7 865.2,605.1"
              />
              <rect
                id="ADIOSAMORRIGHT"
                x="865.2"
                y="487.4"
                className="st11"
                width="317.7"
                height="117.6"
              />
              <polygon
                id="TEFALLELEFT"
                className="st10"
                points="807.8,605.1 807.8,722.7 493,722.7 490,717.5 490,605.1"
              />
              <rect
                id="ADIOSAMORLEFT"
                x="490"
                y="487.4"
                className="st11"
                width="317.7"
                height="117.6"
              />
              <path
                className="st9"
                d="M592.4,544.3h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L592.4,544.3z M593.2,549.3h2.3l-1.2-2.5L593.2,549.3
                                z"
              />
              <path
                className="st9"
                d="M608.9,544.3c2.4,0.2,4.2,2.2,4,4.6c-0.1,2.1-1.9,3.9-4,4h-7.6v-8.6H608.9z M608.3,547h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C609.5,547.7,608.9,547.2,608.3,547L608.3,547z"
              />
              <path className="st9" d="M617.3,544.3v8.6H614v-8.6H617.3z" />
              <path
                className="st9"
                d="M630,548.3v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2S630,545,630,548.3z
                                M626.7,548.5c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V548.5z"
              />
              <path
                className="st9"
                d="M636.6,547.6c2.4,0.1,5.8,0.3,5.8,2.4c0,2.5-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.5,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.2-2.3,0.8C634.2,547.5,635.3,547.6,636.6,547.6z"
              />
              <path
                className="st9"
                d="M653.6,544.3h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5H649L653.6,544.3z M654.4,549.3h2.4l-1.2-2.5L654.4,549.3z
                                "
              />
              <path
                className="st9"
                d="M662.4,544.3h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.3,4.6h-1.7l-3.3-4.6v4.6h-3.3V544.3z"
              />
              <path
                className="st9"
                d="M690.4,548.3v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2S690.4,545,690.4,548.3z
                                M687.1,548.5c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V548.5z"
              />
              <path
                className="st9"
                d="M699.7,544.3c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.3,1.2l1.9,2.4h-3.6
                                l-1.6-2.1h-3v2.1h-3.3v-8.6L699.7,544.3z M694.7,546.8v1.5h4.1c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7
                                c0,0,0,0,0,0L694.7,546.8z"
              />
              <path
                className="st9"
                d="M974.7,544.3h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L974.7,544.3z M975.5,549.3h2.4l-1.2-2.5L975.5,549.3
                                z"
              />
              <path
                className="st9"
                d="M991.1,544.3c2.4,0.2,4.2,2.2,4,4.6c-0.1,2.1-1.9,3.9-4,4h-7.6v-8.6H991.1z M990.5,547h-3.7v3.1h3.7
                                c0.9-0.1,1.5-1,1.3-1.8C991.7,547.7,991.2,547.2,990.5,547z"
              />
              <path className="st9" d="M999.5,544.3v8.6h-3.3v-8.6H999.5z" />
              <path
                className="st9"
                d="M1012.2,548.3v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.9-4.2v-0.6c0-3.2,2.6-4.2,5.9-4.2
                                S1012.2,545,1012.2,548.3z M1008.9,548.5c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V548.5
                                z"
              />
              <path
                className="st9"
                d="M1018.8,547.6c2.4,0.1,5.8,0.3,5.8,2.4c0,2.5-2.8,3-5.8,3s-5.5-0.4-5.8-3h3.5c0.4,0.7,1.3,0.8,2.3,0.8
                                s2.3-0.2,2.3-0.8c0-0.4-1-0.4-2.4-0.5c-2.4-0.1-5.8-0.3-5.8-2.4c0-2.5,2.8-3,5.8-3s5.5,0.4,5.8,3h-3.5c-0.4-0.7-1.3-0.8-2.3-0.8
                                s-2.3,0.2-2.3,0.8C1016.5,547.5,1017.5,547.6,1018.8,547.6z"
              />
              <path
                className="st9"
                d="M1035.8,544.3h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L1035.8,544.3z M1036.7,549.3h2.3l-1.2-2.5
                                L1036.7,549.3z"
              />
              <path
                className="st9"
                d="M1044.7,544.3h4.2l3.3,4.3l3.3-4.3h4.2v8.6h-3.3v-4.6l-3.3,4.6h-1.7l-3.3-4.6v4.6h-3.3V544.3z"
              />
              <path
                className="st9"
                d="M1072.6,548.3v0.6c0,3.2-2.6,4.2-5.8,4.2s-5.9-0.9-5.8-4.2v-0.6c0-3.2,2.6-4.2,5.8-4.2
                                S1072.6,545,1072.6,548.3z M1069.3,548.5c0-1.4-1.1-1.7-2.5-1.7s-2.5,0.3-2.5,1.7v0.2c0,1.4,1.1,1.7,2.5,1.7s2.5-0.3,2.5-1.7V548.5
                                z"
              />
              <path
                className="st9"
                d="M1081.9,544.3c1.8,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0c0,0.6-0.2,1.2-0.5,1.8c-0.3,0.5-0.8,0.9-1.4,1.2l1.9,2.4
                                h-3.6l-1.6-2.1h-3v2.1h-3.3v-8.6L1081.9,544.3z M1077,546.8v1.5h4.1c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-0.4-0.3-0.7-0.7-0.7
                                c0,0,0,0,0,0L1077,546.8z"
              />
              <path
                className="st9"
                d="M607.3,663.9V658h-4v-2.7h11.3v2.7h-4v5.9H607.3z"
              />
              <path
                className="st9"
                d="M615.8,655.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V655.3z"
              />
              <path
                className="st9"
                d="M632.5,655.3h10.5v2.7h-7.1v1h6.3v2.5h-6.3v2.3h-3.3V655.3z"
              />
              <path
                className="st9"
                d="M646.1,655.3h4l4.6,8.6H651l-0.7-1.5H646l-0.7,1.5h-3.8L646.1,655.3z M647,660.3h2.3l-1.2-2.5L647,660.3z"
              />
              <path
                className="st9"
                d="M658.3,655.3v5.9h6.7v2.7h-10v-8.6H658.3z"
              />
              <path
                className="st9"
                d="M669.5,655.3v5.9h6.7v2.7h-10v-8.6H669.5z"
              />
              <path
                className="st9"
                d="M677.3,655.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V655.3z"
              />
              <path
                className="st9"
                d="M989.6,663.9V658h-4v-2.7h11.3v2.7h-4v5.9H989.6z"
              />
              <path
                className="st9"
                d="M998,655.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V655.3z"
              />
              <path
                className="st9"
                d="M1014.7,655.3h10.5v2.7h-7.1v1h6.3v2.5h-6.3v2.3h-3.3V655.3z"
              />
              <path
                className="st9"
                d="M1028.4,655.3h4l4.6,8.6h-3.8l-0.7-1.5h-4.3l-0.7,1.5h-3.8L1028.4,655.3z M1029.2,660.3h2.3l-1.2-2.5
                                L1029.2,660.3z"
              />
              <path
                className="st9"
                d="M1040.6,655.3v5.9h6.7v2.7h-10v-8.6H1040.6z"
              />
              <path
                className="st9"
                d="M1051.7,655.3v5.9h6.7v2.7h-10v-8.6H1051.7z"
              />
              <path
                className="st9"
                d="M1059.6,655.3h10v2.2h-6.7v1.1h6.7v2h-6.7v1.1h6.7v2.2h-10V655.3z"
              />
            </g>
          </svg>
        </div>
        {SectionHover && false && (
          <div className="infoSec">
            {
              <>
                {SectionHover.url.trim().toLowerCase() === 'agotado' && (
                  <div className="d-flex">
                    <b className="d-block">AGOTADO</b>
                  </div>
                )}
                <div className="d-flex">
                  <b className="d-block">Sección: </b>
                  <span>{SectionHover.name}</span>
                </div>
                {moneda === 'USD'
                  ? SectionHover.preciousd.trim() !== '' && (
                      <div className="d-flex">
                        <b className="d-block">Precio: </b>
                        <span>desde {SectionHover.preciousd}</span>
                      </div>
                    )
                  : SectionHover.preciobs.trim() !== '' && (
                      <div className="d-flex">
                        <b className="d-block">Precio: </b>
                        <span>desde {SectionHover.preciobs}</span>
                      </div>
                    )}
              </>
            }
          </div>
        )}
      </section>
    </section>
  );
};

export default Mapa;
