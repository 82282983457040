import React from 'react';

const Footer = () => {
  return (
    <footer className="ld--footer">
      <div className="container">
        <span className="">
          <img
            className="ld--logo-tm me-4 my-2"
            src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}los-templo-paganos-logo-ticketmundo-white.png`}
            alt="Logo de la Ruta de los Templos Paganos"
          />
	  
          <img
            className="ld--logo-tm me-4 my-2"
            src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}polar-logo.png`}
            alt="Logo de Empresas Polar"
          />
        </span>
        <img
          className="ld--logo-tm my-2"
          src={process.env.REACT_APP_URL_IMAGES_COMMONS + 'logo-footer.svg'}
          alt="Ticketmundo"
        />
      </div>
    </footer>
  );
};

export default Footer;
