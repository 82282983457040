/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import moment from 'moment';
moment.locale('es');
const Precios = ({ precios, mapaUrl, fees }) => {
  return (
    <section className="ld--wrap-precios mt-4">
      <h1 className="ld--title" />
      <div className="ld--precios">
        {mapaUrl && (
          <div className="ld--mapa ld--card">
            <figure>
              <img src={mapaUrl} alt="mapa precios" />
            </figure>
          </div>
        )}
        <div>
          <div className="ld--detalles ld--card">
            {precios.map((p, idx) => (
              <div className="ld--item-precio" key={idx}>
                <span
                  className="ld--color"
                  style={{ backgroundColor: p.color }}
                ></span>
                <div className="ld--info">
                  <div className="ld--nombre">{p.nombre}</div>
                  <div className="ld--descripcion">{p.descripcion}</div>
                </div>
                <div className="ld--precio">{p.precio}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Precios;
