import React from 'react';

const Header = ({ urlBanner, urlBannerMovil }) => {
    return (
        <header className='ld--header'>
            <div className="fixed-top">
                <div className="container px-2 px-md-0">
                    <a href="https://ticketmundo.com.ve">
                        <svg width="40px" height="23px" viewBox="0 0 40 23" xmlns="http://www.w3.org/2000/svg" style={{ fill: `var(--color__text)` }}>
                            <path opacity="0.4" d="M27.4245 12.2836C23.8067 19.0163 21.1365 19.6769 20.7382 19.7406C20.3513 19.7159 19.9736 19.6096 19.6289 19.4284C18.3027 18.7584 17.0333 17.123 15.9933 15.4056C15.7728 15.0209 15.5583 14.6482 15.3497 14.2876C15.3444 14.2804 15.3408 14.2729 15.3355 14.264C14.4288 12.6974 13.6235 11.3451 12.9088 10.1779C12.8946 10.1543 12.8804 10.1325 12.8679 10.1107C16.5586 3.38138 19.2609 2.80794 19.6005 2.76074H19.6022C19.9954 2.78302 20.3796 2.89005 20.7293 3.07478C22.0556 3.74261 23.3232 5.37816 24.365 7.0954C24.5854 7.48041 24.7987 7.85252 25.0085 8.21375C25.0139 8.22176 25.0187 8.23021 25.0227 8.23899C25.9188 9.78577 26.7152 11.1254 27.4245 12.2836Z"></path>
                            <path d="M39.9582 10.8972C39.3164 12.2423 35.8479 19.2982 33.6577 19.7465H20.6851L20.7366 19.7411C21.1348 19.6774 23.8051 19.0168 27.4229 12.284C28.9483 9.44497 30.6425 5.52401 32.4043 0.112557C32.4135 0.0837854 32.4304 0.0582098 32.453 0.0386571C32.4757 0.0191044 32.5032 0.00634736 32.5325 0.00182904C32.5618 -0.00268928 32.5918 0.00120371 32.6191 0.0130636C32.6463 0.0249235 32.6699 0.0442819 32.687 0.0689926L35.6772 4.3677C35.5697 4.69653 35.4999 5.03699 35.4692 5.38225C35.3235 6.95444 35.9617 8.30137 36.8933 8.39032C37.3164 8.43025 37.727 8.20516 38.0648 7.80036L39.9262 10.4779C39.9681 10.5383 39.9932 10.6092 39.9989 10.683C40.0045 10.7568 39.9904 10.8308 39.9582 10.8972V10.8972Z"></path>
                            <path d="M19.635 2.75781C19.6236 2.75825 19.6124 2.75946 19.6012 2.76145H19.5994C19.2599 2.80864 16.5576 3.38208 12.8669 10.1114C11.2953 12.9759 9.54596 16.9585 7.72371 22.4894C7.71416 22.5188 7.69685 22.545 7.67368 22.5649C7.65052 22.5849 7.62239 22.598 7.59242 22.6027C7.56244 22.6074 7.53179 22.6035 7.50382 22.5915C7.47585 22.5796 7.45167 22.5599 7.43393 22.5348L4.36189 18.1675C4.4741 17.8319 4.54691 17.4839 4.57878 17.1308C4.72634 15.5515 4.07922 14.1976 3.12988 14.1068C2.69787 14.0669 2.27476 14.2974 1.93164 14.7113L0.0756205 12.0737C0.0329649 12.0129 0.00728558 11.9415 0.0013357 11.867C-0.00461419 11.7925 0.00938888 11.7177 0.0418418 11.6507C0.696072 10.3002 4.22145 3.208 6.44725 2.75781H19.635Z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
            <figure className="banner-desktop">
                <img src={urlBanner} alt='banner'/>
            </figure>
            <figure className="banner-movil">
                <img src={urlBannerMovil} alt='banner'/>
            </figure>
        </header>
    );
};

export default Header;
