/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import './map.scss';

const Mapa = ({
  onClick,
  Color = '',
  closeModal,
  host,
  entradas = null,
  moneda,
}) => {
  const [Sections, setSections] = useState([]);
  const [SectionHover, setSectionHover] = useState(null);
  useEffect(() => {
    let blocks = document.querySelectorAll(
      '#TODOSLOSVERDES,#GOLD,#MESASEE2,#TANDING'
    );
    setSections(blocks);
    const scc = document.querySelector('#scroll-svg');
    console.log(scc.getBoundingClientRect().width, scc.scrollWidth);
    scc.scrollTo(
      (scc.scrollWidth - scc.getBoundingClientRect().width) / 2,
      (scc.scrollHeight - scc.getBoundingClientRect().height) / 2
    );
  }, []);
  useEffect(() => {
    if (Sections.length === 0) return;
    Array.from(Sections).map((s, i) => {
      s.addEventListener('mouseenter', hover);
      s.addEventListener('mouseleave', blur);
      if (entradas[s.id].trim().toLowerCase() === 'agotado') {
        s.style.fill = '#9e9e9e';
        s.style.cursor = 'not-allowed';
      }
      s.addEventListener('click', (e) => click(s.id));
      const childern = s.childNodes;
      if (childern) {
        childern.forEach((ch) => {
          ch.addEventListener('click', (e) => click(s.id));
          if (entradas[s.id].trim().toLowerCase() === 'agotado') {
            ch.style.fill = '#9e9e9e';
            ch.style.cursor = 'not-allowed';
          }
        });
      }
    });
    return () => {
      Array.from(Sections).map((s, i) => {
        s.removeEventListener('mouseenter', hover);
        s.removeEventListener('mouseleave', blur);
        s.removeEventListener('click', click);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Sections]);
  const hover = (e) => {
    Array.from(Sections).map((s, i) => {
      if (e.target.id === s.id) {
        s.classList.add('active');
        setSectionHover({
          ...e.target.dataset,
          url: entradas[e.target.id],
        });
      }
    });
  };
  const click = (section) => {
    if (
      entradas[section].trim() !== '' &&
      entradas[section].trim().toLowerCase() !== 'agotado'
    ) {
      window.location.href = `${entradas[section]}/?host=${host}`;
    }
  };
  const blur = (e) => {
    Array.from(Sections).map((s, i) => {
      s.classList.remove('active');
      setSectionHover(null);
    });
  };
  return (
    <section id="modalMap">
      <span className="overlay" onClick={() => closeModal()}></span>
      <section id="modalContent">
        <span className="cerrar" onClick={() => closeModal()}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x "
          >
            <g>
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </g>
          </svg>
        </span>
        <h3 className="text-center mb-4">Seleccionar zona</h3>
        <div id="scroll-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1163.99 1344.58"
            id="mapaC"
          >
            <g id="Capa_2" data-name="Capa 2">
              <g id="Maps">
                <path
                  className="cls-1"
                  d="M2.68,982.21l-1.29,0L1,872.87a.83.83,0,0,1,.84-.83h0a.82.82,0,0,1,.81.82V982.2Z"
                />
                <rect
                  className="cls-2"
                  x="0.83"
                  width="1163.16"
                  height="110.05"
                  rx="1.85"
                />
                <g id="TODOSLOSVERDES">
                  <rect
                    className="cls-3"
                    x="0.83"
                    y="124.16"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-4"
                    x="0.83"
                    y="248.32"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-5"
                    x="0.83"
                    y="372.48"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-6"
                    x="0.83"
                    y="496.63"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-7"
                    x="0.83"
                    y="620.79"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                </g>
                <rect
                  id="MESASEE2"
                  className="cls-8"
                  x="0.83"
                  y="741.42"
                  width="1163.16"
                  height="110.05"
                  rx="2.68"
                />
                <g id="GOLD">
                  <rect
                    className="cls-9"
                    x="0.83"
                    y="862.05"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-10"
                    x="0.83"
                    y="986.21"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                  <rect
                    className="cls-11"
                    x="0.83"
                    y="1110.37"
                    width="1163.16"
                    height="110.05"
                    rx="2.68"
                  />
                </g>
                <rect
                  id="TANDING"
                  className="cls-12"
                  x="0.83"
                  y="1234.53"
                  width="1163.16"
                  height="110.05"
                  rx="2.68"
                />
                <g className="cls-13">
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M478.6,65l-.1-20.13,14.93-.07v3.41l-10.86.05,0,4.46,10.11-.05v3.39l-10.1.05,0,5.48,11.25,0,0,3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M496.29,58.39l3.95-.4a4.86,4.86,0,0,0,1.47,2.91,4.35,4.35,0,0,0,3,.92,4.47,4.47,0,0,0,2.95-.84,2.5,2.5,0,0,0,1-1.95,1.86,1.86,0,0,0-.43-1.21,3.35,3.35,0,0,0-1.46-.87c-.48-.16-1.57-.45-3.26-.86a11.1,11.1,0,0,1-4.6-2,5.24,5.24,0,0,1-1.87-4,5.09,5.09,0,0,1,.86-2.88,5.38,5.38,0,0,1,2.5-2.05,9.8,9.8,0,0,1,4-.72q3.79,0,5.71,1.63a5.86,5.86,0,0,1,2,4.43l-4.06.19a3.47,3.47,0,0,0-1.13-2.22,4.12,4.12,0,0,0-2.58-.67,4.67,4.67,0,0,0-2.77.74,1.48,1.48,0,0,0-.64,1.26,1.56,1.56,0,0,0,.61,1.21,10.8,10.8,0,0,0,3.74,1.33,20.13,20.13,0,0,1,4.4,1.43,5.62,5.62,0,0,1,2.23,2,6.07,6.07,0,0,1,.82,3.21,5.85,5.85,0,0,1-.95,3.24A5.74,5.74,0,0,1,509,64.5a11.35,11.35,0,0,1-4.38.76,8.74,8.74,0,0,1-5.87-1.74A7.69,7.69,0,0,1,496.29,58.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M529,57.38l3.95,1.23a8.86,8.86,0,0,1-3,4.91,8.55,8.55,0,0,1-5.34,1.63A8.67,8.67,0,0,1,518,62.44,10.46,10.46,0,0,1,515.35,55c0-3.35.83-6,2.55-7.82a8.92,8.92,0,0,1,6.82-2.81,8.39,8.39,0,0,1,6,2.16,7.87,7.87,0,0,1,2.07,3.69l-4,1a4.15,4.15,0,0,0-4.25-3.35,4.55,4.55,0,0,0-3.63,1.62q-1.38,1.6-1.37,5.21,0,3.81,1.4,5.43a4.43,4.43,0,0,0,3.58,1.6,4,4,0,0,0,2.78-1A5.92,5.92,0,0,0,529,57.38Z"
                    />
                    <path
                      className="cls-14"
                      d="M536.41,64.75l-.09-20.13,14.92-.07,0,3.4L540.4,48l0,4.46,10.11,0v3.39l-10.1,0,0,5.48,11.25-.05v3.39Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M555.56,64.61l-.1-20.13,4,0,8.3,13.41-.07-13.44,3.78,0,.1,20.13-4.08,0-8.18-13.09.06,13.12Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M596.82,64.42l-4.42,0-1.78-4.56-8,0-1.64,4.58-4.31,0,7.74-20.16,4.3,0Zm-7.52-7.93L586.49,49l-2.69,7.49Z"
                    />
                    <path
                      className="cls-14"
                      d="M599,64.41l-.1-20.13,8.55,0a14.6,14.6,0,0,1,4.7.52,4.63,4.63,0,0,1,2.35,1.92,5.8,5.8,0,0,1,.89,3.16,5.38,5.38,0,0,1-1.31,3.75,6.39,6.39,0,0,1-4,1.88,9.72,9.72,0,0,1,2.19,1.68,23.62,23.62,0,0,1,2.33,3.26l2.47,3.91-4.86,0-3-4.37A30.3,30.3,0,0,0,607.11,57a3.23,3.23,0,0,0-1.23-.83,6.78,6.78,0,0,0-2-.22H603l0,8.4Zm4-11.64h3a15.24,15.24,0,0,0,3.65-.27,2.14,2.14,0,0,0,1.14-.85,2.6,2.6,0,0,0,.4-1.51,2.36,2.36,0,0,0-.55-1.64,2.46,2.46,0,0,0-1.53-.79c-.33,0-1.32-.06-3-.05l-3.17,0Z"
                    />
                    <path
                      className="cls-14"
                      d="M619.16,64.31l-.1-20.13,4.07,0,.09,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M626.22,54.34a12.77,12.77,0,0,1,.9-5.17A9.37,9.37,0,0,1,629,46.4a7.84,7.84,0,0,1,2.59-1.82,11,11,0,0,1,4.34-.82A9.48,9.48,0,0,1,643,46.49q2.69,2.74,2.7,7.66t-2.61,7.64a10.57,10.57,0,0,1-14.22.08A10.4,10.4,0,0,1,626.22,54.34Zm4.19-.16a7.54,7.54,0,0,0,1.6,5.18,5.44,5.44,0,0,0,8,0,7.7,7.7,0,0,0,1.53-5.26A7.54,7.54,0,0,0,640,48.92a5.63,5.63,0,0,0-8.08.06A7.6,7.6,0,0,0,630.41,54.18Z"
                    />
                  </g>
                </g>
                <g className="cls-13">
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M515.9,441.14,515.81,421l6.08,0,3.72,13.72L529.15,421l6.1,0,.09,20.13-3.77,0-.08-15.85-3.92,15.87h-3.91l-4.06-15.82.08,15.84Z"
                    />
                    <path
                      className="cls-14"
                      d="M539.38,441l-.1-20.13,14.93-.07,0,3.41-10.87.05,0,4.46,10.1-.05,0,3.39-10.11.05,0,5.48,11.24-.05,0,3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M557.07,434.4l4-.4a4.8,4.8,0,0,0,1.46,2.91,4.35,4.35,0,0,0,3,.92,4.51,4.51,0,0,0,2.95-.84,2.47,2.47,0,0,0,1-1.95,1.8,1.8,0,0,0-.42-1.21,3.33,3.33,0,0,0-1.47-.87c-.48-.16-1.56-.45-3.26-.86a11,11,0,0,1-4.59-2,5.25,5.25,0,0,1-1.88-4,5.17,5.17,0,0,1,.86-2.88,5.43,5.43,0,0,1,2.5-2,9.86,9.86,0,0,1,4-.72c2.53,0,4.43.53,5.72,1.63a5.86,5.86,0,0,1,2,4.43l-4.07.19a3.42,3.42,0,0,0-1.12-2.22,4.16,4.16,0,0,0-2.58-.67,4.65,4.65,0,0,0-2.77.74,1.48,1.48,0,0,0-.64,1.25,1.51,1.51,0,0,0,.61,1.22,10.63,10.63,0,0,0,3.74,1.33,20,20,0,0,1,4.39,1.43,5.65,5.65,0,0,1,2.24,2,6.39,6.39,0,0,1-.13,6.45,5.81,5.81,0,0,1-2.71,2.26,11.54,11.54,0,0,1-4.38.76,8.74,8.74,0,0,1-5.87-1.74A7.69,7.69,0,0,1,557.07,434.4Z"
                    />
                    <path
                      className="cls-14"
                      d="M595,440.77l-4.42,0-1.78-4.56-8,0-1.64,4.58-4.31,0,7.74-20.16,4.3,0Zm-7.52-7.93-2.81-7.46L582,432.87Z"
                    />
                    <path
                      className="cls-14"
                      d="M596.14,434.21l4-.4a4.8,4.8,0,0,0,1.46,2.92,4.42,4.42,0,0,0,3,.92,4.54,4.54,0,0,0,3-.85,2.43,2.43,0,0,0,1-1.94,1.85,1.85,0,0,0-.42-1.22,3.33,3.33,0,0,0-1.47-.86c-.47-.16-1.56-.45-3.26-.87a11.06,11.06,0,0,1-4.59-2,5.31,5.31,0,0,1-1-6.93,5.52,5.52,0,0,1,2.51-2,10.15,10.15,0,0,1,4-.72,8.51,8.51,0,0,1,5.71,1.64,5.84,5.84,0,0,1,2,4.42l-4.06.2a3.5,3.5,0,0,0-1.13-2.23,4.1,4.1,0,0,0-2.58-.66,4.6,4.6,0,0,0-2.77.74,1.46,1.46,0,0,0-.64,1.25,1.55,1.55,0,0,0,.61,1.22,10.63,10.63,0,0,0,3.74,1.33,19.91,19.91,0,0,1,4.39,1.42,5.74,5.74,0,0,1,2.24,2,6,6,0,0,1,.82,3.2,6,6,0,0,1-1,3.25,5.76,5.76,0,0,1-2.71,2.26,11.5,11.5,0,0,1-4.37.75,8.72,8.72,0,0,1-5.87-1.74A7.65,7.65,0,0,1,596.14,434.21Z"
                    />
                  </g>
                </g>
                <g className="cls-13">
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M211.11,807.78l3.95,1.23a8.86,8.86,0,0,1-3,4.91,9.51,9.51,0,0,1-11.95-1.08,10.46,10.46,0,0,1-2.61-7.48q0-5,2.55-7.82a8.9,8.9,0,0,1,6.81-2.81,8.35,8.35,0,0,1,6,2.15,7.92,7.92,0,0,1,2.08,3.7l-4,1a4.23,4.23,0,0,0-1.5-2.46,4.32,4.32,0,0,0-2.76-.9,4.57,4.57,0,0,0-3.62,1.63q-1.38,1.6-1.37,5.21,0,3.81,1.4,5.43a4.46,4.46,0,0,0,3.58,1.6,4,4,0,0,0,2.78-1A6,6,0,0,0,211.11,807.78Z"
                    />
                    <path
                      className="cls-14"
                      d="M218.68,815.14l-.09-20,4.06,0,.08,16.57,10.11,0v3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M235.63,815.06l-.1-20.12,4.07,0,.09,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M256.41,807.57l3.94,1.23a8.84,8.84,0,0,1-3,4.91,9.49,9.49,0,0,1-11.94-1.09,10.51,10.51,0,0,1-2.62-7.48q0-5,2.56-7.81a8.89,8.89,0,0,1,6.81-2.82,8.37,8.37,0,0,1,6,2.16,8,8,0,0,1,2.08,3.7l-4,1A4.15,4.15,0,0,0,252,798a4.59,4.59,0,0,0-3.62,1.62c-.93,1.08-1.38,2.81-1.37,5.21s.48,4.36,1.4,5.43a4.46,4.46,0,0,0,3.57,1.61,4.13,4.13,0,0,0,2.79-1.05A6.06,6.06,0,0,0,256.41,807.57Z"
                    />
                    <path
                      className="cls-14"
                      d="M263.92,814.93l-.09-20.13,4.06,0,.05,8.94,8.16-9,5.47,0L274,802.59l8.05,12.26-5.26,0-5.58-9.42L268,808.83l0,6.08Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M322.66,814.66l-4.42,0-1.78-4.57-8,0-1.64,4.58-4.31,0,7.74-20.17,4.3,0Zm-7.52-7.93-2.81-7.46-2.69,7.48Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M342,812a13.52,13.52,0,0,0,3.27,1.69l-1.49,2.87a9.9,9.9,0,0,1-1.8-.74c-.13-.07-1-.66-2.71-1.77a10.94,10.94,0,0,1-4.38.88,9.57,9.57,0,0,1-7.28-2.7c-1.76-1.81-2.65-4.37-2.67-7.66s.85-5.85,2.6-7.68a10.66,10.66,0,0,1,14.26-.07c1.75,1.82,2.64,4.37,2.65,7.66a13.18,13.18,0,0,1-.7,4.59A9.37,9.37,0,0,1,342,812Zm-3.27-2.28a6.22,6.22,0,0,0,1.16-2.23,10.49,10.49,0,0,0,.38-3,7.65,7.65,0,0,0-1.57-5.21,5.56,5.56,0,0,0-8.05.05,7.63,7.63,0,0,0-1.52,5.21,7.8,7.8,0,0,0,1.57,5.27,5,5,0,0,0,3.92,1.73,4.77,4.77,0,0,0,1.65-.29,11.09,11.09,0,0,0-2.51-1.25l1.12-2.33A13.9,13.9,0,0,1,338.75,809.73Z"
                    />
                    <path
                      className="cls-14"
                      d="M347.58,794.41l4.07,0,.05,10.9a21.68,21.68,0,0,0,.17,3.36,3.18,3.18,0,0,0,1.25,2,4.34,4.34,0,0,0,2.69.74,4.13,4.13,0,0,0,2.6-.72,2.77,2.77,0,0,0,1-1.74,21.89,21.89,0,0,0,.16-3.42l-.05-11.14,4.07,0,.05,10.58a27.64,27.64,0,0,1-.31,5.12,5.78,5.78,0,0,1-1.2,2.53,6.18,6.18,0,0,1-2.36,1.65,10.42,10.42,0,0,1-3.87.63,11.33,11.33,0,0,1-4.38-.64,6.29,6.29,0,0,1-2.36-1.72,5.48,5.48,0,0,1-1.15-2.23,24.43,24.43,0,0,1-.42-5.1Z"
                    />
                    <path
                      className="cls-14"
                      d="M367.89,814.44l-.09-20.13,4.06,0,.09,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M396.24,794.41l4.06,0,.05,10.9a23.11,23.11,0,0,0,.17,3.36,3.27,3.27,0,0,0,1.25,2,4.34,4.34,0,0,0,2.69.74,4.18,4.18,0,0,0,2.61-.72,2.77,2.77,0,0,0,1-1.74,22.88,22.88,0,0,0,.17-3.42l-.06-11.14,4.07,0,0,10.57A27.93,27.93,0,0,1,412,810a5.69,5.69,0,0,1-1.21,2.53,6.1,6.1,0,0,1-2.36,1.65,10.42,10.42,0,0,1-3.87.63,11.18,11.18,0,0,1-4.38-.64,6.37,6.37,0,0,1-2.36-1.72,5.61,5.61,0,0,1-1.15-2.23,24.43,24.43,0,0,1-.42-5.1Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M416.53,814.21l-.09-20,4.06,0,.08,16.57,10.11,0v3.4Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M438.28,814.11l-.07-16.72-6,0V794l16-.08,0,3.41-6,0,.08,16.72Z"
                    />
                    <path
                      className="cls-14"
                      d="M450.81,814.05l-.1-20.13,4.07,0,.09,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M458.69,814l-.1-20.13,6.08,0,3.72,13.71,3.55-13.75,6.09,0,.1,20.13-3.78,0-.07-15.84L470.36,814l-3.92,0-4.05-15.83.07,15.85Z"
                    />
                    <path
                      className="cls-14"
                      d="M500.32,813.82l-4.42,0-1.78-4.57-8.05,0-1.64,4.58-4.31,0,7.75-20.16,4.29,0Zm-7.52-7.93L490,798.43l-2.69,7.49Z"
                    />
                    <path
                      className="cls-14"
                      d="M501.41,807.26l3.95-.4a4.86,4.86,0,0,0,1.47,2.92,4.4,4.4,0,0,0,2.95.92,4.51,4.51,0,0,0,3-.85,2.47,2.47,0,0,0,1-2,1.83,1.83,0,0,0-.43-1.21,3.34,3.34,0,0,0-1.46-.86c-.48-.16-1.57-.45-3.26-.87a11,11,0,0,1-4.6-2,5.28,5.28,0,0,1-1-6.92,5.49,5.49,0,0,1,2.5-2.05,10.15,10.15,0,0,1,4-.72q3.79,0,5.71,1.64a5.89,5.89,0,0,1,2,4.42l-4.07.2a3.45,3.45,0,0,0-1.13-2.23,4.06,4.06,0,0,0-2.58-.66,4.6,4.6,0,0,0-2.77.74,1.45,1.45,0,0,0-.63,1.25,1.54,1.54,0,0,0,.6,1.22,10.79,10.79,0,0,0,3.75,1.33,19.56,19.56,0,0,1,4.39,1.42,5.71,5.71,0,0,1,2.23,2,6.35,6.35,0,0,1-.12,6.45,5.74,5.74,0,0,1-2.71,2.25,11.35,11.35,0,0,1-4.38.76,8.74,8.74,0,0,1-5.87-1.74A7.69,7.69,0,0,1,501.41,807.26Z"
                    />
                    <path
                      className="cls-14"
                      d="M529,813.68l-.1-20.13,6.08,0,3.72,13.71,3.55-13.75,6.09,0,.1,20.13-3.78,0-.07-15.85-3.92,15.87-3.92,0-4-15.83.07,15.85Z"
                    />
                    <path
                      className="cls-14"
                      d="M552.46,813.57l-.09-20.13,14.93-.07v3.41l-10.86.05,0,4.46,10.11,0v3.39l-10.1,0,0,5.48,11.25,0v3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M570.16,806.94l4-.4a4.86,4.86,0,0,0,1.47,2.91,4.35,4.35,0,0,0,2.95.92,4.47,4.47,0,0,0,3-.84,2.5,2.5,0,0,0,1-1.95,1.88,1.88,0,0,0-.43-1.21,3.44,3.44,0,0,0-1.46-.87c-.48-.16-1.57-.45-3.26-.86a11.1,11.1,0,0,1-4.6-2,5.24,5.24,0,0,1-1.87-4,5.09,5.09,0,0,1,.86-2.88,5.38,5.38,0,0,1,2.5-2,10.15,10.15,0,0,1,4-.72c2.52,0,4.43.53,5.71,1.63a5.86,5.86,0,0,1,2,4.43l-4.06.2a3.47,3.47,0,0,0-1.13-2.23,4.12,4.12,0,0,0-2.58-.67,4.67,4.67,0,0,0-2.77.74,1.48,1.48,0,0,0-.64,1.26,1.56,1.56,0,0,0,.61,1.21,10.8,10.8,0,0,0,3.74,1.33,20.13,20.13,0,0,1,4.4,1.43,5.57,5.57,0,0,1,2.23,2,6,6,0,0,1,.82,3.2,5.85,5.85,0,0,1-.95,3.24,5.68,5.68,0,0,1-2.7,2.26,11.35,11.35,0,0,1-4.38.76,8.74,8.74,0,0,1-5.87-1.74A7.69,7.69,0,0,1,570.16,806.94Z"
                    />
                    <path
                      className="cls-14"
                      d="M608.13,813.31l-4.42,0-1.78-4.56-8,0-1.64,4.58-4.31,0,7.75-20.16,4.29,0Zm-7.52-7.93-2.81-7.45-2.69,7.48Z"
                    />
                    <path
                      className="cls-14"
                      d="M609.22,806.75l4-.4a4.8,4.8,0,0,0,1.46,2.92,4.35,4.35,0,0,0,3,.92,4.51,4.51,0,0,0,2.95-.85,2.47,2.47,0,0,0,1-1.94,1.85,1.85,0,0,0-.42-1.22,3.45,3.45,0,0,0-1.47-.86c-.48-.16-1.56-.45-3.26-.86a11.16,11.16,0,0,1-4.6-2,5.29,5.29,0,0,1-1-6.93,5.56,5.56,0,0,1,2.5-2,10.15,10.15,0,0,1,4-.72q3.79,0,5.71,1.64a5.87,5.87,0,0,1,2,4.42l-4.07.2a3.42,3.42,0,0,0-1.13-2.22,4,4,0,0,0-2.57-.67,4.58,4.58,0,0,0-2.77.74,1.44,1.44,0,0,0-.64,1.25,1.52,1.52,0,0,0,.61,1.22,10.63,10.63,0,0,0,3.74,1.33,19.48,19.48,0,0,1,4.39,1.43,5.65,5.65,0,0,1,2.24,2,6.39,6.39,0,0,1-.13,6.45,5.81,5.81,0,0,1-2.71,2.26,11.56,11.56,0,0,1-4.38.75,8.79,8.79,0,0,1-5.87-1.73A7.72,7.72,0,0,1,609.22,806.75Z"
                    />
                    <path
                      className="cls-14"
                      d="M636.85,813.17,636.76,793l14.92-.07,0,3.41-10.86,0,0,4.46,10.1,0,0,3.39-10.11,0,0,5.47,11.25,0v3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M655.61,813.09l-.1-20.13,14.93-.07v3.4l-10.86.05,0,4.46,10.11,0,0,3.39-10.11.05,0,5.47,11.24,0,0,3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M686.53,809.36v3.58L673,813a9.23,9.23,0,0,1,1.3-3.85,25.08,25.08,0,0,1,4.31-4.85,27.62,27.62,0,0,0,3.19-3.31,4.25,4.25,0,0,0,.78-2.37,2.68,2.68,0,0,0-.7-2,3,3,0,0,0-3.84.06,3.74,3.74,0,0,0-.81,2.42l-3.85-.37a6.27,6.27,0,0,1,2.14-4.58,7.23,7.23,0,0,1,4.52-1.41,6.56,6.56,0,0,1,4.69,1.58,5.26,5.26,0,0,1,1.72,4,6.84,6.84,0,0,1-.47,2.59,10.49,10.49,0,0,1-1.53,2.58,25.25,25.25,0,0,1-2.52,2.58c-1.21,1.13-2,1.87-2.3,2.24a6.36,6.36,0,0,0-.79,1.07Z"
                    />
                    <path
                      className="cls-14"
                      d="M697.71,792.76l7.42,0a14.52,14.52,0,0,1,3.84.37,6.68,6.68,0,0,1,3,1.84,8.8,8.8,0,0,1,1.94,3.25,15,15,0,0,1,.68,4.76,13,13,0,0,1-.6,4.28,8.9,8.9,0,0,1-2.14,3.57,7.12,7.12,0,0,1-2.85,1.62,12.28,12.28,0,0,1-3.59.44l-7.65,0Zm4.08,3.38.06,13.34,3,0a10.82,10.82,0,0,0,2.46-.2,3.78,3.78,0,0,0,1.64-.85,4.22,4.22,0,0,0,1-1.95,13.34,13.34,0,0,0,.4-3.68,12.14,12.14,0,0,0-.43-3.59,4.65,4.65,0,0,0-1.16-1.94,3.93,3.93,0,0,0-1.89-.94,19,19,0,0,0-3.34-.17Z"
                    />
                    <path
                      className="cls-14"
                      d="M718,812.79l-.1-20.13,4.07,0,.09,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M724.87,806.21l4-.4a4.77,4.77,0,0,0,1.46,2.91,4.35,4.35,0,0,0,3,.92,4.46,4.46,0,0,0,3-.84,2.5,2.5,0,0,0,1-1.95,1.83,1.83,0,0,0-.43-1.21,3.38,3.38,0,0,0-1.46-.87c-.48-.16-1.56-.45-3.26-.86a11.16,11.16,0,0,1-4.6-2,5.24,5.24,0,0,1-1.87-4,5.17,5.17,0,0,1,.86-2.88,5.43,5.43,0,0,1,2.5-2,10.15,10.15,0,0,1,4-.72q3.79,0,5.71,1.63a5.89,5.89,0,0,1,2,4.43l-4.07.2a3.42,3.42,0,0,0-1.13-2.23,4.11,4.11,0,0,0-2.57-.67,4.72,4.72,0,0,0-2.77.74,1.46,1.46,0,0,0-.64,1.26,1.54,1.54,0,0,0,.61,1.22,10.68,10.68,0,0,0,3.74,1.32,20,20,0,0,1,4.39,1.43,5.57,5.57,0,0,1,2.23,2,6.34,6.34,0,0,1-.12,6.44,5.76,5.76,0,0,1-2.71,2.26,11.35,11.35,0,0,1-4.38.76,8.74,8.74,0,0,1-5.87-1.74A7.69,7.69,0,0,1,724.87,806.21Z"
                    />
                    <path
                      className="cls-14"
                      d="M744.69,812.67l-.09-20.13,6.52,0a23.54,23.54,0,0,1,4.83.28,5.24,5.24,0,0,1,2.91,2,6.24,6.24,0,0,1,1.18,3.92,6.6,6.6,0,0,1-.65,3.12,5.66,5.66,0,0,1-1.7,2,6,6,0,0,1-2.11,1,23,23,0,0,1-4.21.31h-2.65l0,7.6Zm4-16.75,0,5.71h2.23a10.07,10.07,0,0,0,3.21-.33,2.6,2.6,0,0,0,1.26-1,2.69,2.69,0,0,0,.46-1.57,2.62,2.62,0,0,0-.66-1.81,2.79,2.79,0,0,0-1.64-.88,20,20,0,0,0-2.92-.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M762.57,802.64a12.77,12.77,0,0,1,.9-5.17,9.32,9.32,0,0,1,1.86-2.76,7.88,7.88,0,0,1,2.59-1.83,11.08,11.08,0,0,1,4.34-.82,9.46,9.46,0,0,1,7.13,2.73c1.79,1.83,2.69,4.39,2.71,7.66s-.86,5.8-2.62,7.64a9.32,9.32,0,0,1-7.07,2.79,9.47,9.47,0,0,1-7.15-2.71A10.43,10.43,0,0,1,762.57,802.64Zm4.19-.16a7.54,7.54,0,0,0,1.61,5.18,5.43,5.43,0,0,0,8,0,7.69,7.69,0,0,0,1.54-5.26,7.61,7.61,0,0,0-1.54-5.16,5.64,5.64,0,0,0-8.09.06A7.6,7.6,0,0,0,766.76,802.48Z"
                    />
                    <path
                      className="cls-14"
                      d="M785.36,812.47l-.1-20.12,4,0,8.3,13.4-.06-13.44,3.77,0,.1,20.13-4.08,0-8.18-13.09.06,13.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M805.5,812.38l-.09-20.13,4.06,0,.1,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M813.36,792.21l8,0a24.07,24.07,0,0,1,3.57.19,5.08,5.08,0,0,1,3.65,2.49,4.51,4.51,0,0,1,.63,2.36,4.58,4.58,0,0,1-.75,2.59,4.64,4.64,0,0,1-2.06,1.78,5.33,5.33,0,0,1,2.84,1.82,4.8,4.8,0,0,1,1,3,5.93,5.93,0,0,1-.62,2.67,5.3,5.3,0,0,1-1.74,2.08,5.7,5.7,0,0,1-2.72,1c-.67.08-2.31.13-4.9.16l-6.85,0Zm4.08,3.33,0,4.66h2.66c1.59,0,2.57,0,3-.09a2.63,2.63,0,0,0,1.64-.72,2.13,2.13,0,0,0,.59-1.58,2.2,2.2,0,0,0-.52-1.51,2.3,2.3,0,0,0-1.54-.7c-.4-.05-1.56-.07-3.47-.06Zm0,8,0,5.38,3.76,0a18.44,18.44,0,0,0,2.79-.13,2.61,2.61,0,0,0,1.47-.81,2.49,2.49,0,0,0,.56-1.72,2.63,2.63,0,0,0-.44-1.53,2.57,2.57,0,0,0-1.28-.92,14.7,14.7,0,0,0-3.6-.27Z"
                    />
                    <path
                      className="cls-14"
                      d="M833.85,812.25l-.09-20,4.06,0,.08,16.57,10.11,0v3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M850.92,812.17,850.83,792l14.92-.07,0,3.4-10.86,0,0,4.46,10.1,0,0,3.39-10.11,0,0,5.47,11.25,0v3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M868.62,805.53l4-.4a4.8,4.8,0,0,0,1.46,2.92,4.4,4.4,0,0,0,3,.92,4.56,4.56,0,0,0,3-.85,2.46,2.46,0,0,0,1-2,1.82,1.82,0,0,0-.42-1.21,3.33,3.33,0,0,0-1.47-.86c-.48-.17-1.56-.45-3.26-.87a10.92,10.92,0,0,1-4.59-2,5.3,5.3,0,0,1-1-6.92,5.47,5.47,0,0,1,2.51-2.05,10.13,10.13,0,0,1,4-.72,8.54,8.54,0,0,1,5.72,1.64,5.84,5.84,0,0,1,2,4.42l-4.06.2a3.5,3.5,0,0,0-1.13-2.23,4.1,4.1,0,0,0-2.58-.66,4.58,4.58,0,0,0-2.77.74,1.46,1.46,0,0,0-.64,1.25,1.52,1.52,0,0,0,.61,1.22,10.63,10.63,0,0,0,3.74,1.33,19.56,19.56,0,0,1,4.39,1.42,5.74,5.74,0,0,1,2.24,2,6.32,6.32,0,0,1-.13,6.45,5.74,5.74,0,0,1-2.71,2.25,11.34,11.34,0,0,1-4.37.76,8.75,8.75,0,0,1-5.88-1.74A7.68,7.68,0,0,1,868.62,805.53Z"
                    />
                  </g>
                </g>
                <g className="cls-13">
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M536.22,1048.77l0-3.4,8.76,0,0,8a11.14,11.14,0,0,1-3.69,2.2,13.65,13.65,0,0,1-4.91,1,11,11,0,0,1-5.51-1.3,8.47,8.47,0,0,1-3.55-3.77,12.35,12.35,0,0,1-1.2-5.36,11.59,11.59,0,0,1,1.29-5.59,8.85,8.85,0,0,1,3.84-3.77,10.36,10.36,0,0,1,4.82-1,9.58,9.58,0,0,1,5.86,1.54,7.1,7.1,0,0,1,2.73,4.33l-4,.78A4.31,4.31,0,0,0,539,1040a4.82,4.82,0,0,0-2.94-.84,5.54,5.54,0,0,0-4.23,1.71,7.12,7.12,0,0,0-1.55,5,7.92,7.92,0,0,0,1.62,5.36,5.36,5.36,0,0,0,4.19,1.78,7,7,0,0,0,2.55-.52,8.69,8.69,0,0,0,2.2-1.22v-2.55Z"
                    />
                    <path
                      className="cls-14"
                      d="M547.89,1046.17a13,13,0,0,1,.89-5.17,9.72,9.72,0,0,1,1.86-2.76,7.91,7.91,0,0,1,2.6-1.83,11,11,0,0,1,4.33-.82,9.45,9.45,0,0,1,7.13,2.73,10.55,10.55,0,0,1,2.71,7.66q0,4.88-2.61,7.64a9.35,9.35,0,0,1-7.07,2.79,9.5,9.5,0,0,1-7.16-2.71A10.38,10.38,0,0,1,547.89,1046.17Zm4.18-.16a7.59,7.59,0,0,0,1.61,5.18,5.14,5.14,0,0,0,4,1.74,5.08,5.08,0,0,0,4-1.76,7.74,7.74,0,0,0,1.53-5.26,7.55,7.55,0,0,0-1.54-5.16,5.63,5.63,0,0,0-8.08.06A7.6,7.6,0,0,0,552.07,1046Z"
                    />
                    <path
                      className="cls-14"
                      d="M570.74,1056l-.09-20,4.06,0,.08,16.57,10.1,0,0,3.39Z"
                    />
                    <path
                      className="cls-14"
                      d="M587.7,1035.8l7.43,0a14.07,14.07,0,0,1,3.83.37,6.63,6.63,0,0,1,3,1.84,8.72,8.72,0,0,1,1.94,3.25,15,15,0,0,1,.68,4.75,13.07,13.07,0,0,1-.6,4.29,8.79,8.79,0,0,1-2.14,3.57,7.13,7.13,0,0,1-2.84,1.61,12.36,12.36,0,0,1-3.6.45l-7.65,0Zm4.08,3.38.06,13.33h3a10.64,10.64,0,0,0,2.45-.21,3.61,3.61,0,0,0,1.64-.84,4.28,4.28,0,0,0,1-2,13,13,0,0,0,.4-3.69,12.07,12.07,0,0,0-.43-3.58,4.65,4.65,0,0,0-1.16-1.94,3.85,3.85,0,0,0-1.89-.94,18.66,18.66,0,0,0-3.34-.18Z"
                    />
                  </g>
                </g>
                <g className="cls-13">
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M487.74,1292.76l3.95-.41a4.8,4.8,0,0,0,1.46,2.92,4.35,4.35,0,0,0,3,.92,4.56,4.56,0,0,0,3-.84,2.49,2.49,0,0,0,1-1.95,1.8,1.8,0,0,0-.42-1.21,3.24,3.24,0,0,0-1.47-.87c-.48-.16-1.56-.45-3.26-.86a11,11,0,0,1-4.59-2,5.25,5.25,0,0,1-1.88-4,5.17,5.17,0,0,1,.86-2.88,5.43,5.43,0,0,1,2.5-2,9.86,9.86,0,0,1,4-.72,8.49,8.49,0,0,1,5.72,1.63,5.86,5.86,0,0,1,2,4.43l-4.07.19a3.42,3.42,0,0,0-1.12-2.22,4.1,4.1,0,0,0-2.58-.67,4.65,4.65,0,0,0-2.77.74,1.46,1.46,0,0,0-.64,1.25,1.51,1.51,0,0,0,.61,1.22,10.63,10.63,0,0,0,3.74,1.33,19.48,19.48,0,0,1,4.39,1.43,5.65,5.65,0,0,1,2.24,2,6.32,6.32,0,0,1-.13,6.45,5.81,5.81,0,0,1-2.71,2.26,11.37,11.37,0,0,1-4.38.75,8.68,8.68,0,0,1-5.87-1.73A7.64,7.64,0,0,1,487.74,1292.76Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M513.64,1299.18l-.08-16.72-6,0,0-3.41,16-.07v3.4l-6,0,.08,16.73Z"
                    />
                  </g>
                  <g className="cls-13">
                    <path
                      className="cls-14"
                      d="M545.65,1299l-4.42,0-1.78-4.56-8.05,0-1.64,4.58-4.31,0,7.74-20.17,4.3,0Zm-7.52-7.93-2.81-7.45-2.69,7.48Z"
                    />
                    <path
                      className="cls-14"
                      d="M547.84,1299l-.09-20.13,4,0,8.3,13.41-.06-13.44,3.78,0,.09,20.13-4.08,0-8.17-13.09.06,13.12Z"
                    />
                    <path
                      className="cls-14"
                      d="M568,1278.8l7.43,0a14.92,14.92,0,0,1,3.83.37,6.8,6.8,0,0,1,3,1.84,8.78,8.78,0,0,1,1.93,3.25,14.49,14.49,0,0,1,.68,4.76,13.33,13.33,0,0,1-.59,4.28,8.9,8.9,0,0,1-2.14,3.57,7.12,7.12,0,0,1-2.85,1.62,12.4,12.4,0,0,1-3.6.44l-7.64,0Zm4.08,3.38.06,13.34,3,0a10.94,10.94,0,0,0,2.46-.2,3.74,3.74,0,0,0,1.63-.85,4.14,4.14,0,0,0,1.06-2,13,13,0,0,0,.39-3.68,12.14,12.14,0,0,0-.43-3.59,4.51,4.51,0,0,0-1.16-1.94,3.89,3.89,0,0,0-1.89-.94,18.84,18.84,0,0,0-3.33-.17Z"
                    />
                    <path
                      className="cls-14"
                      d="M588.29,1298.83l-.09-20.13,4.06,0,.1,20.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M596.27,1298.79l-.09-20.12,4,0,8.3,13.4-.06-13.44,3.77,0,.1,20.13-4.08,0L600,1285.65l.06,13.13Z"
                    />
                    <path
                      className="cls-14"
                      d="M625.87,1291.25l0-3.39,8.76,0,0,8A11,11,0,0,1,631,1298a13.42,13.42,0,0,1-4.91,1,11.09,11.09,0,0,1-5.51-1.3,8.44,8.44,0,0,1-3.55-3.78,12.27,12.27,0,0,1-1.2-5.35,11.63,11.63,0,0,1,1.29-5.6,8.88,8.88,0,0,1,3.84-3.76,10.36,10.36,0,0,1,4.82-1,9.62,9.62,0,0,1,5.86,1.55,7.07,7.07,0,0,1,2.73,4.33l-4,.77a4.32,4.32,0,0,0-1.61-2.33,4.82,4.82,0,0,0-2.94-.84,5.51,5.51,0,0,0-4.23,1.71,7.06,7.06,0,0,0-1.54,5,7.92,7.92,0,0,0,1.61,5.37,5.33,5.33,0,0,0,4.19,1.77,6.82,6.82,0,0,0,2.55-.51,9,9,0,0,0,2.2-1.22v-2.56Z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        {SectionHover && false && (
          <div className="infoSec">
            {
              <>
                {SectionHover.url.trim().toLowerCase() === 'agotado' && (
                  <div className="d-flex">
                    <b className="d-block">AGOTADO</b>
                  </div>
                )}
                <div className="d-flex">
                  <b className="d-block">Sección: </b>
                  <span>{SectionHover.name}</span>
                </div>
                {moneda === 'USD'
                  ? SectionHover.preciousd.trim() !== '' && (
                      <div className="d-flex">
                        <b className="d-block">Precio: </b>
                        <span>desde {SectionHover.preciousd}</span>
                      </div>
                    )
                  : SectionHover.preciobs.trim() !== '' && (
                      <div className="d-flex">
                        <b className="d-block">Precio: </b>
                        <span>desde {SectionHover.preciobs}</span>
                      </div>
                    )}
              </>
            }
          </div>
        )}
      </section>
    </section>
  );
};

export default Mapa;
