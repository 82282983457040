import React from 'react';

const Intro = () => {
  return (
    <div className="mb-5">
      <h2 className="ld--title">{process.env.REACT_APP_TITLE}</h2>
      <section className="ld--intro ld--card mb-0">
    <p>
    Únete a nosotros en un recorrido lleno de lugares mágicos que te harán ver a nuestra adorada Caracas con otros ojos.
    </p>
    <p>Los Templos Paganos son una invitación a descubrir espacios legendarios del centro y el oeste de la ciudad; sitios repletos de historia urbana, dedicados a la fiesta y al culto a las bebidas espirituosas.</p>
    <p>Tendemos un puente entre personas, sitios y comunidades que están tan cerca y a la vez tan lejos, para celebrar juntos en un ambiente festivo, seguro y libre de prejuicios.</p>
      </section>
    </div>
  );
};

export default Intro;
