import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './views/Home';
import Help from './views/contactUs';
import { favicons } from './data/favicons';

const App = () => {
  return (
    <main className={`App theme ${process.env.REACT_APP_THEME}`}>
      <Helmet>
        {favicons.map((icon, index) => (
          <link key={index} {...icon} />
        ))}
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <title>{process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_META_DESC} />
      </Helmet>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/help/" element={<Help />} />
          <Route path="/help/:item" element={<Help />} />
        </Routes>
      </Router>
    </main>
  );
};

export default App;
