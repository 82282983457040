import React from 'react';
const Notas = ({ notas }) => {
    return (
        <section className="ld--wrap-notas">
            <div className="ld--title">Normas y Recomendaciones</div>
            <div className="ld--card">
                <ul>
                    {notas.map((n, idx) => (
                        <li key={idx}><span>{n}</span></li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Notas;
