const faviconBaseUrl = process.env.REACT_APP_FAVICONS;

export const favicons = [
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-57x57.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-60x60.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-72x72.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-76x76.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-114x114.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-120x120.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-144x144.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-152x152.png`,
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    type: 'image/png',
    href: `${faviconBaseUrl}apple-icon-180x180.png`,
  },
  {
    rel: 'icon',
    sizes: '192x192',
    type: 'image/png',
    href: `${faviconBaseUrl}android-icon-192x192.png`,
  },
  {
    rel: 'icon',
    sizes: '32x32',
    type: 'image/png',
    href: `${faviconBaseUrl}favicon-32x32.png`,
  },
  {
    rel: 'icon',
    sizes: '96x96',
    type: 'image/png',
    href: `${faviconBaseUrl}favicon-96x96.png`,
  },
  {
    rel: 'icon',
    sizes: '16x16',
    type: 'image/png',
    href: `${faviconBaseUrl}favicon-16x16.png`,
  },
  { rel: 'manifest', href: `${faviconBaseUrl}manifest.json` },
  {
    rel: 'msapplication-TileImage',
    content: `${faviconBaseUrl}ms-icon-144x144.png`,
  },
];
