import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Payment, ResendCode, Soporte, Back } from '../components/iconSvg';
import { items } from '../components/items';
import axios from 'axios';
import intlTelInput from 'intl-tel-input';
import utilsIntTelInput from 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ItemSelected from '../components/itemSelected';
import BounceLoader from 'react-spinners/BounceLoader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import '../styles/contactUs.sass';

const axiosApi = axios.create({
  mode: 'cors',
  headers: { 'content-type': 'application/json' },
});
const ContactUs = () => {
  const location = useLocation();
  console.log(location);
  const [itemActive, setItemActive] = useState(null);
  const [nombre, setNombre] = useState('');
  const [errors, setErrors] = useState({});
  const [correo, setCorreo] = useState('');
  const [, setPhoneError] = useState('');
  const [, setPais] = useState('');
  const [telefono, setTelefono] = useState('');
  const [asunto, setAsunto] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [sending, setSending] = useState(false);
  const { item } = useParams();
  const phone = React.useRef(null);
  useEffect(() => {
    if (itemActive !== null) {
      document.querySelector('#telefono').value = '';
      setNombre('');
      setAsunto('');
      setCorreo('');
    }
  }, [itemActive]);
  useEffect(() => {
    const input = document.querySelector('#telefono');
    phone.current = null;
    if (input) {
      phone.current = intlTelInput(input, {
        utilsScript: utilsIntTelInput,
        separateDialCode: true,
        formatOnDisplay: false,
        initialCountry: 'auto',
        geoIpLookup: (success, failure) => {
          const ajax = axios.create();
          ajax({
            method: 'get',
            url: 'https://ip-geolocation.whoisxmlapi.com/api/v1?apiKey=at_0GxVjUPZDtRQuTiMq4t3bjk5VYft8',
          }).then((resp) => {
            const countryCode =
              resp && resp.data.location.country
                ? resp.data.location.country
                : '';
            success(countryCode);
          });
        },
        customPlaceholder: (
          selectedCountryPlaceholder,
          selectedCountryData
        ) => {
          return 'Teléfono móvil';
        },
      });

      input.addEventListener('countrychange', () => {
        setPais(phone.current.getSelectedCountryData().iso2);
        setTelefono('');
      });

      input.addEventListener('change', () => {
        setTelefono(phone.current.getNumber());
      });

      input.addEventListener('blur', (e) => {
        setPhoneError(null);

        if (input.value.trim() !== '') {
          if (!phone.current.isValidNumber()) {
            setPhoneError('Número no válido');
          }
        }
      });
    }
  }, [itemActive]);
  useEffect(() => {
    if (item) {
      const _I = items.find((n) => n.id === item);
      setItemActive(_I);
    } else {
      setItemActive(null);
    }
  }, [item]);
  useEffect(() => {
    if (
      nombre.trim() !== '' &&
      telefono.trim() !== '' &&
      correo.trim() !== '' &&
      asunto.trim() !== ''
    ) {
      setIsValid(true);
    }
  }, [nombre, correo, telefono, asunto]);
  const submit = (e) => {
    e.preventDefault();
    let valid = true;
    let _E = {};
    if (nombre.trim() === '') {
      _E = { ..._E, nombre: 'Campo obligatorio' };
      valid = false;
    }
    if (correo.trim() === '') {
      _E = { ..._E, correo: 'Campo obligatorio' };
      valid = false;
    }
    const re = /^([\da-z_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/;
    if (!re.exec(correo)) {
      _E = { ..._E, correo: 'Correo no valido' };
      valid = false;
    }
    if (asunto.trim() === '') {
      _E = { ..._E, asunto: 'Campo obligatorio' };
      valid = false;
    }
    console.log(_E);
    setErrors(_E);
    if (!valid) {
      return;
    }
    const datos = {
      Nombre: nombre,
      Telefono: telefono,
      Email: correo,
      Texto: asunto,
      Website: 'Somos Vinotinto',
      RegionID: 4,
    };
    //  console.log(datos);return;
    setSending(true);
    axiosApi
      .post(
        'https://apitm.ticketmundo.com/api/landingpais/EnviarContact',
        JSON.stringify(datos)
      )
      .then((resp) => {
        if (resp.data === true) {
          setSending(false);
          toast.success('Se ha enviado el correo de manera correcta', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setNombre('');
          setCorreo('');
          setAsunto('');
          setTelefono('');
        }
      })
      .finally(() => {
        setSending(false);
      });
  };

  const sendCode = () => {
    // Swal.fire({
    //   title:`Servicio no disponible`
    // })
    Swal.fire({
      customClass: {
        title: 'titleCode',
        input: 'inputCode',
        inputLabel: 'labelCode',
        validationMessage: 'validacionCode',
        confirmButton: 'confirmCode bg-btn-ppal',
        cancelButton: 'cancelCode',
      },
      title: 'Ingresa el correo con el que realizaste la compra',
      // input: 'text',
      // inputAttributes: {
      //   autocapitalize: 'off'
      // },
      html: `<div id="wrapCorreoCompra">
        <input type="text" placeholder="Email de la compra" id="email-compra"/>
        <div id="wrap-sec" class="d-none">
          <input type="text" placeholder="Email alternativo" id="email-secundario" />
          <!--span class="d-block" id="msgEmail">Por favor ingresa otro correo diferente a hotmail u outlook para enviarte los tickets</span-->
        </div>
      </div>`,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      didOpen: () => {
        // let emailCompra = document.getElementById("email-compra");
        // let emailSecundario = document.getElementById("wrap-sec");
        //  console.log(emailCompra.value);
        // emailCompra.addEventListener("keyup", (e) => {
        //   if (
        //     // emailCompra.value.includes("hotmail") ||
        //     // emailCompra.value.includes("outlook")
        //     false
        //   ) {
        //     emailSecundario.classList.remove("d-none");
        //   } else {
        //     emailSecundario.classList.add("d-none");
        //   }
        // });
      },
      preConfirm: () => {
        let emailCompra = document.getElementById('email-compra');
        let emailSecundario = document.getElementById('email-secundario');
        let re = /^([\da-z_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/;
        if (!re.exec(emailCompra.value.toLowerCase())) {
          Swal.showValidationMessage(`<p class="mb-0">Correo no válido</p>`);
          return;
        }
        let needSec =
          emailCompra.value.includes('hotmail') ||
          emailCompra.value.includes('outlook');
        let ruta = `https://apivinotinto.azurewebsites.net/api/confirmacion/EnviarEticketPorEmail/${emailCompra.value.toLowerCase()}/es/1748`;

        return axios
          .get(ruta)
          .then((response) => {
            console.log(response);
            if (response.data) {
              return {
                response: response.data,
                email: needSec ? emailSecundario.value : emailCompra.value,
              };
            } else {
              let msgEmail = document.getElementById('msgEmail');
              msgEmail.classList.remove('d-block');
              msgEmail.classList.add('d-none');
              Swal.showValidationMessage(
                `<p class="mb-0">No encontramos tu correo en nuestros registros. Contáctate con nosotros haciendo clic <a href="mailto:contactoguaco@ticketmundo.com">aquí</a> </p>`
              );
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              // Swal.fire({
              //   title:`Ocurrio un error al verificar transacción o la transacción no existe para ese correo`,
              // })
              Swal.showValidationMessage(
                `<p class="mb-0">No encontramos tu correo en nuestros registros. Contáctate con nosotros haciendo clic <a href="mailto:contactoguaco@ticketmundo.com">aquí</a> </p>`
              );
            }
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed && result.value.response) {
        Swal.fire({
          customClass: {
            title: 'titleCode',
            confirmButton: 'bg-btn-ppal',
          },
          title: `Listo! <br/>
                  Reenviamos tu compra con tu ticket al email: <br/>
                  ${result.value.email}
                  `,
        });
      }
    });
  };
  return (
    <section id="pageContactUs">
      <div className="container">
        {!itemActive ? (
          <>
            <Link to="/" className="my-4 d-block back-arrow">
              <Back />
            </Link>
            <figure className="logo">
              <img
                src={process.env.REACT_APP_URL_IMAGES + 'logo-kany.png'}
                alt=""
              />
            </figure>
            <h1 className="title">
              Hola, dinos ¿Cómo podemos ayudarte hoy?{' '}
              <img
                src={process.env.REACT_APP_URL_IMAGES_COMMONS + 'mano.png'}
                alt="mano"
                className="ml-2"
              />
            </h1>

            <section className="opciones">
              <div className="row justify-content-center">
                <div className="col col-12 col-sm-6 col-lg-4">
                  <div
                    className="card d-flex alig-items-stretch"
                    onClick={() => sendCode()}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="content d-flex align-items-center ">
                      <figure className="d-flex align-items-center">
                        <ResendCode />
                      </figure>
                      <h3 className="titleCard text-center">
                        Reenviar tickets
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-sm-6 col-lg-4">
                  <Link to="/help/soporte">
                    <div className="card d-flex alig-items-stretch">
                      <div className="content d-flex align-items-center">
                        <figure className="d-flex align-items-center">
                          <Soporte />
                        </figure>
                        <h3 className="titleCard">Soporte al usuario</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col col-12 col-sm-6 col-lg-4">
                  <Link to="/help/payment">
                    <div className="card d-flex alig-items-stretch">
                      <div className="content d-flex align-items-center">
                        <figure className="d-flex align-items-center">
                          <Payment />
                        </figure>
                        <h3 className="titleCard">
                          Métodos de Pagos y Verificaciones
                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <ItemSelected item={itemActive} sendCode={sendCode} />
            <div
              className="redes-news w-100 d-flex justify-content-center"
              style={{ marginBottom: '8rem' }}
            >
              <a
                href="https://api.whatsapp.com/send?phone=584123604583"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  style={{
                    width: '45px',
                    marginRight: '20px',
                  }}
                  src={
                    process.env.REACT_APP_URL_IMAGES_COMMONS + 'whatsapp.svg'
                  }
                  alt="Whatsapp"
                />
              </a>
              <a
                href="https://telegram.me/ticketmundo_bot"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  style={{
                    width: '45px',
                    marginRight: '20px',
                  }}
                  src={
                    process.env.REACT_APP_URL_IMAGES_COMMONS + 'telegram.svg'
                  }
                  alt="Telegram"
                />
              </a>
              <a
                href="https://www.instagram.com/ticketmundo_ve"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  style={{
                    width: '45px',
                    marginRight: '20px',
                  }}
                  src={
                    process.env.REACT_APP_URL_IMAGES_COMMONS + 'instagram.svg'
                  }
                  alt="Instagram"
                />
              </a>
              <a
                href="https://m.me/ticketmundo"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  style={{
                    width: '45px',
                    marginRight: '20px',
                  }}
                  src={
                    process.env.REACT_APP_URL_IMAGES_COMMONS + 'messenger.svg'
                  }
                  alt="Messenger"
                />
              </a>
              <a
                href={`mailto:atencionalcliente@ticketmundo.com`}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img
                  style={{
                    width: '45px',
                    marginRight: '20px',
                  }}
                  src={process.env.REACT_APP_URL_IMAGES_COMMONS + 'email.svg'}
                  alt="Email"
                />
              </a>
            </div>
            <div className="contacto">
              <h1 className="title">
                No encontraste solución a tu problema?
                <br />
                Escríbenos y te contactamos lo antes posible
                <img
                  src={process.env.REACT_APP_URL_IMAGES_COMMONS + 'mail.png'}
                  alt="mano"
                  className="ml-2"
                />
              </h1>
              {sending ? (
                <div
                  style={{
                    width: '100%',
                    maxWidth: '500px',
                    height: '400px',
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <BounceLoader
                    size={80}
                    color={'currentColor'}
                    loading={true}
                  />
                </div>
              ) : (
                <form onSubmit={submit}>
                  <div className="wrapInput">
                    <input
                      type="text"
                      className="inputCus"
                      maxLength="50"
                      placeholder="Nombre"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                    {errors.hasOwnProperty('nombre') && (
                      <span className="error">{errors.nombre}</span>
                    )}
                  </div>
                  <div className="wrapInput">
                    <input
                      type="text"
                      className="inputCus"
                      maxLength="150"
                      placeholder="Correo electrónico"
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                    {errors.hasOwnProperty('correo') && (
                      <span className="error">{errors.correo}</span>
                    )}
                  </div>
                  <div className="wrapInput">
                    <input
                      type="text"
                      className="inputCus"
                      maxLength="15"
                      placeholder="Teléfono"
                      id="telefono"
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                    {errors.hasOwnProperty('telefono') && (
                      <span className="error">{errors.telefono}</span>
                    )}
                  </div>
                  <div className="wrapInput">
                    <textarea
                      type="text"
                      className="areaCus"
                      placeholder="Mensaje"
                      value={asunto}
                      onChange={(e) => setAsunto(e.target.value)}
                    ></textarea>
                    {errors.hasOwnProperty('asunto') && (
                      <span className="error">{errors.asunto}</span>
                    )}
                  </div>
                  <div className="wrapInput">
                    <button
                      type="submit"
                      className="btn btnGen btn-block"
                      disabled={!isValid}
                    >
                      Enviar{' '}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactUs;
