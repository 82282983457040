import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Countdown from 'react-countdown';
import Cola from '../../images/colaIcon.svg';
import paypal from '../../images/paypal.png';
import zelle from '../../images/zelle.png';
import card from '../../images/card.png';
import mercantil from '../../images/mercantil.png';
import banesco from '../../images/banesco.png';
import c2p from '../../images/c2p.png';
import pagomovil from '../../images/pagomovil.png';
import bnc from '../../images/bnc.png';
import debito from '../../images/debito.png';
const WaitingIn = ({ position, time, callback, setMoneda, moneda }) => {
    const [TimerAgotado, setTimerAgotado] = useState(false);

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if (typeof callback === 'function') {
                callback();
            }
            setTimerAgotado(true);
            return null;
        } else {
            // Render a countdown
            return (
                <>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                </>
            );
        }
    };

    return (
        <div id="waitingL">
            <div className="content">
                <div className="wrapContent">
                    <img src={Cola} className="colaIcon" alt="" />
                    <h1 className="">Por favor espera,</h1>
                    {!TimerAgotado ? (
                        <>
                            <p>
                                Hay alto tráfico para este evento, pronto serás
                                habilitado para accesar a comprar tus entradas.
                                Estas en el puesto numero:
                            </p>

                            <h2>{position}</h2>
                            <i>
                                El tiempo estimado es de{' '}
                                <b>
                                    {
                                        <Countdown
                                            date={Date.now() + time * 1000}
                                            renderer={renderer}
                                        />
                                    }
                                </b>{' '}
                            </i>
                        </>
                    ) : (
                        <>
                            <p>
                                Estamos procesando su pocisión en la cola para
                                permitir la compra.
                            </p>
                        </>
                    )}
                    <p>
                        Te mostraremos las opciones de compra al llegar tu
                        turno. Por favor no cierres esta ventana ni actualices
                        el navegador ya que perderás el puesto en la cola.
                    </p>
                </div>
                <div className="footerCola">
                    <p>
                        Antes de comenzar selecciona la moneda de tu
                        preferencia.
                    </p>
                    <div className="changeCurrencyWrap">
                        <strong>Pagar en</strong>
                        <ToggleCurrency
                            labelLeft="USD"
                            labelRight="VEF"
                            valueLeft="USD"
                            valueRight="VES"
                            defaultValue={moneda}
                            onChange={(value) => {
                                setMoneda(value);
                            }}
                        />
                    </div>
                    <div className="paymentMethods">
                        <span>Aceptamos:</span>
                        {moneda !== 'VES' ? (
                            <div>
                                <img src={paypal} alt="PayPal" />
                                <img src={zelle} alt="Zelle" />
                                <img src={card} alt="Cards" />
                            </div>
                        ) : (
                            <div>
                                <img src={mercantil} alt="mercantil" />
                                <img src={banesco} alt="banesco" />
                                <img src={c2p} alt="c2ps" />
                                <img src={pagomovil} alt="pago movil" />
                                <img src={bnc} alt="bnc" />
                                <img src={debito} alt="debito" />
                            </div>
                        )}
                        <span>
                            Te recomendamos tener a la mano todos los datos de
                            tu medio de pago preferido
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ToggleCurrency = ({
    onChange,
    valueLeft = 'left',
    valueRight = 'right',
    labelLeft = '',
    labelRight = '',
    defaultValue
}) => {
    const [side, setSide] = useState(
        defaultValue
            ? defaultValue === valueRight
                ? valueRight
                : valueLeft
            : valueLeft
    );
    const changeValue = () => {
        setSide(side === valueLeft ? valueRight : valueLeft);
        if (typeof onChange === 'function') {
            onChange(side === valueLeft ? valueRight : valueLeft);
        }
    };
    return (
        <div className="toggleComponent">
            <p>{labelLeft || ''}</p>
            <div
                className={`wrapperTogle ${
                    side === valueLeft ? 'left' : 'right'
                }`}
                onClick={changeValue}
            >
                <div className="background">
                    <span></span>
                </div>
            </div>
            <p>{labelRight || ''}</p>
        </div>
    );
};

WaitingIn.propTypes = {
    time: PropTypes.number,
    position: PropTypes.number,
    callback: PropTypes.func
};

export default WaitingIn;
