import React, { useState } from 'react';
import '../styles/App.sass';
import data from '../data/data.json';
import {
  Footer,
  Header,
  Funciones,
  Notas,
  Intro,
  Spotify,
  // Spotify,
  // Map,
  // Patrocinantes,
  // CountDown,
} from '../components';
import { Help } from '../components/iconSvg';
import { Link } from 'react-router-dom';
// import moment from 'moment-timezone';

const Home = () => {
  const MONEDAS = ['USD', 'VES'];
  const [moneda, setMoneda] = useState(MONEDAS[0]);
  const [allowBuy] = useState(true);
  // const DIFF_HOR_SEC = moment(
  //     moment().format('DD/MM/YYYY hh:mm a'),
  //     'DD/MM/YYYY hh:mm a'
  // ).diff(
  //     moment(
  //         moment().tz('America/Caracas').format('DD/MM/YYYY hh:mm a'),
  //         'DD/MM/YYYY hh:mm a'
  //     ),
  //     'seconds'
  // );
  return (
    <>
      <Header
        urlBanner={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.jpg`}
        urlBannerMovil={`${process.env.REACT_APP_URL_IMAGES}banner-movil.jpg`}
      />
      <div className="container mb-5">
        {/* <CountDown
                    date={moment(
                        '28/07/2022 11:00 am',
                        'DD/MM/YYYY hh:mm a'
                    ).add(DIFF_HOR_SEC, 'seconds')}
                    onFinish={() => setAllowBuy(true)}
                /> */}
        <Intro />
        <Notas notas={data.notas} />
        <Funciones
          moneda={moneda}
          setMoneda={setMoneda}
          monedas={MONEDAS}
          allowBuy={allowBuy}
        />
    <Spotify />
        <Link to="/help/" className="ld--btn-help">
          <span>
            <Help /> Centro de ayuda
          </span>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Home;
